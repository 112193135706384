import React, { Component } from 'react';
import { Header } from './Header';
import { Textblock } from './Textblock';

export class Impressum extends Component{
    static displayName = "Impressum";

    render() {
        return (
            <div>
                <Header/>
                <div className="container pt-5 text-light">
                    <h1 className="text-center mb-5">Impressum</h1>
                    <Textblock title="Angaben gemäß § 5 TMG">
                        Malte Fischer<br />
                        Driescher Str. 17<br />
                        41564 Kaarst
                    </Textblock>
                    <Textblock title="Kontakt">
                        Telefon: +49 2131 1336514<br/>
                        E-Mail: <a href="mailto:thecat@nimmerneko.de">thecat@nimmerneko.de</a>
                    </Textblock>
                </div> 
            </div>   
        );
    }
}