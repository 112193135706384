import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../content/C58-Light.png';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import WebService from '../service/WebService';

export class Header extends Component{
    static displayName = "Header";

    constructor(props){
        super(props);

        this.state = {
            LoggedIn: false,
            Roles: [],
            LoginExpanded: false,
            MobileExpanded: false,
            ActiveUserName: null,
            ActiveUserEmail: null,
            LoginLoading: false,
            UserLoading: false,
            LoginValid: false,
            Error: false,
            PasswordHidden: true,
            EMail: "",
            Password: "",
            RememberMe: false
        };

        this.webservice = WebService.getInstance();

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.expand = this.expand.bind(this);
        this.expandM = this.expandM.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.eye = this.eye.bind(this);
        this.rm = this.rm.bind(this);

        this.onlogin = this.onlogin.bind(this);
        this.onlogout = this.onlogout.bind(this);
        this.p = 0;

        this.userReq = false;
    }

    componentDidMount(){
        this.webservice.addListeners(this.onlogin, this.onlogout);
        if (this.webservice.isLoggedIn()){
            this.onlogin(this.webservice.getRoles());
        } else {
            this.onlogout();
        }

        if(!this.props.animated) return;

        gsap.registerPlugin(ScrollTrigger);

        gsap.timeline({
            scrollTrigger:{
                trigger: '#root',
                start: 'top top',
                end: '100',
                scrub: true
            }
        }).from("#header-logo", {
            height: '200'
        });

    }

    componentWillUnmount(){
        this.userReq = null;
        this.webservice.removeListeners(this.onlogin, this.onlogout);
    }

    onlogin(roles){
        this.setState({
            LoggedIn: true,
            Roles: roles,
            UserLoading: true
        });
        var req = this.webservice.getUser();
        this.userReq = req;
        this.userReq.then(res => {
            if(req !== this.userReq) return;
            this.setState({
                UserLoading:false,
                ActiveUserName: res.userName,
                ActiveUserEmail: res.eMail
            });
        }).catch(err => {
            this.onlogout();
        });
    }

    onlogout(){
        this.setState({
            LoggedIn: false,
            Roles: [],
            UserLoading: false,
            ActiveUserName: null,
            ActiveUserEmail: null
        });
    }

    inputChange(event){
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            var res = true;
            if (this.state.EMail === "") res = false;
            if (this.state.Password === "") res = false;
            this.setState({
                LoginValid: res
            })
        });
    }

    login(event){
        event.preventDefault();
        this.setState({
            LoginLoading: true
        });
        this.webservice.login({
            eMail: this.state.EMail,
            password: this.state.Password,
            rememberMe: this.state.RememberMe
        }).then (res => {
            if(res.token === undefined) {
                this.setState({
                    Error: true,
                    LoginLoading: false
                })
                return;
            };
            this.setState({
                LoginLoading: false
            });
        }).catch(() => {
            this.setState({
                Error: true,
                LoginLoading: false
            });
        })
        .finally(() => {});
        this.setState({
            Password: "",
            Valid: false,
            Error: false
        })
    }

    logout(){
        this.webservice.logout().then();
    }

    expand(){
        this.setState({
            LoginExpanded: !this.state.LoginExpanded,
            MobileExpanded: false
        });
    }

    expandM(){
        this.setState({
            MobileExpanded: !this.state.MobileExpanded,
            LoginExpanded: false
        });
    }

    eye(){
        this.setState({
            PasswordHidden: !this.state.PasswordHidden
        });
    }

    rm(){
        this.setState({
            RememberMe: !this.state.RememberMe
        });
    }

    render() {
        return (
            <div>
                <div className="d-none d-sm-block" style={{height: '80px'}}>
                    <div id="header" className={`fixed-top d-flex align-items-start w-100 ${this.props.animated?"header-negative-margin":""}`} >
                        <Link to="/"><img id="header-logo" alt="Website Logo" src={logo} height='80px' style={{height: '80px'}} className="bg-dark shadow-lg img-fluid border border-dark rounded"/></Link>
                        <Link to="/" className={`grow-1 ${this.props.minimal?" d-none":""}`}><button className="btn btn-dark p-1 navbutton w-100 shadow-md text-light"><i className="bi bi-house-door"/> Home</button></Link>
                        <Link to="/projects" className={`grow-1 ${this.props.minimal?" d-none":""}`}><button className="btn btn-dark p-1 navbutton w-100 shadow-md text-light"><i className="bi bi-kanban"/> Projects</button></Link>
                        <Link to="/catplug" className={`grow-1 ${this.props.minimal||!this.state.Roles.includes("catplug")?" d-none":""}`}><button className="btn btn-dark p-1 navbutton w-100 shadow-md text-light"><i className="bi bi-plug"/> Catplug</button></Link>
                        <Link to="/impressum" className={`grow-1 ${this.props.minimal?" d-none":""}`}><button className="btn btn-dark p-1 navbutton w-100 shadow-md text-light"><i className="bi bi-info-circle"/> Impressum</button></Link>
                        <div className={`grow-1 ${this.props.minimal?'':'d-none'}`}/>
                        <div className={this.state.LoginExpanded&&!this.state.LoggedIn?'':'d-none'}>
                            <div className="navbutton bg-dark rounded p-2 d-flex">
                                <form className="row row-cols-auto g-2 align-items-center align-self-center" onSubmit={this.login}>
                                    <Link to="/signup"><button type="button" title="To Signup page" className="btn btn-sm btn-light"><i className="bi bi-people"/><span className="visually-hidden">To Signup page</span></button></Link>
                                    <Link to="/login"><button type="button" title="To Login page" className="btn btn-sm btn-light"><i className="bi bi-box-arrow-in-right"/><span className="visually-hidden">To Login page</span></button></Link>
                                    <div>
                                        <label htmlFor="header-login-email" className="visually-hidden">E-Mail Address</label>
                                        <div className="input-group">
                                            <input id="header-login-email" type="text" name="EMail" className="form-control form-control-sm bg-light text-dark" placeholder="E-Mail Address" disabled={this.state.LoginLoading} value={this.state.EMail} onChange={this.inputChange}/>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="header-login-password" className="visually-hidden">Password</label>
                                        <div className="input-group">
                                            <input id="header-login-password" type={`${this.state.PasswordHidden?'password':'text'}`} name="Password"  placeholder="Password" className="form-control form-control-sm bg-light text-dark" onChange={this.inputChange} value={this.state.Password} disabled={this.state.LoginLoading}/>
                                            <button className="btn btn-sm btn-light " type="button" onClick={this.eye} title="Toggle wether password is hidden or not">
                                                <i className={`bi ${this.state.PasswordHidden?'bi-eye-slash-fill':'bi-eye-fill'}`}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <input name="RememberMe" id="header-login-rememberme" type="checkbox" title="Stay Logged in" className="btn-check" autoComplete="off" onChange={this.rm} checked={this.state.RememberMe}/>
                                        <label htmlFor="header-login-rememberme" title="Stay Logged in" className="btn btn-sm btn-outline-light me-1"><i className={this.state.RememberMe?'bi bi-bookmark-check-fill':'bi bi-bookmark-fill'}/><span className="visually-hidden">Stay Logged In</span></label>
                                    </div>
                                    <button type="submit" title="Login" className={`btn btn-sm btn-primary me-2 ${this.state.LoginLoading?'d-none':''}`} disabled={!this.state.LoginValid}><i className="bi bi-arrow-right"/><span className="visually-hidden">Login</span></button>
                                    <button type="button" title="Loading..." className={`btn btn-sm btn-primary me-2 ${this.state.LoginLoading?'':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/><span className="visually-hidden">Loading...</span></button>
                                    <span className={`text-danger me-1 ${this.state.Error?'':'d-none'}`} title="E-Mail or Password not correct"><i className="bi bi-info-circle"/><p className="visually-hidden">E-Mail or Password not correct</p></span>
                                </form>
                            </div>
                        </div>
                        <div className={this.state.LoginExpanded&&this.state.LoggedIn?'':'d-none'}>
                            <div className="d-flex align-items-center navbutton bg-dark text-light rounded px-3">
                                <div className={this.state.UserLoading?'d-none':'d-flex align-items-center'}>
                                    <button type="button" title="Logout" className='btn btn-sm btn-outline-light me-3' onClick={this.logout}><i className="bi bi-box-arrow-left"/><span className="visually-hidden">Logout</span></button>
                                    <div className="">
                                        {this.state.ActiveUserName}<br/>
                                        <small>{this.state.ActiveUserEmail}</small>
                                    </div>
                                </div>
                                <div className={this.state.UserLoading?'':'d-none'}>
                                    <span className="spinner-border spinner-border-sm"/> Loading user data...
                                </div>
                            </div>
                        </div>
                        <button className={`btn btn-dark px-4 navbutton shadow-md text-light ${this.props.nologin?'d-none':''}`} onClick={this.expand} title="Expand Login Menu"><i className={`bi ${this.state.LoggedIn?this.state.LoginExpanded?'bi-person-dash':'bi-person':this.state.LoginExpanded?'bi-chevron-right':'bi-box-arrow-in-right'}`} style={{fontSize:'1.25rem'}}/><span className="visually-hidden">Expand Login Menu</span></button>
                    </div>
                </div>   
                <div className="d-block d-sm-none">
                    <div className="d-flex align-items-start w-100">
                        <button className={`btn navbutton-sm btn-dark px-3 shadow-md text-light ${this.props.minimal?'d-none':''}`} onClick={this.expandM} title="Expand Nav Menu"><i className="bi bi-list" style={{fontSize:'1.25rem'}}/><span className="visually-hidden">Expand Nav Menu</span></button>
                        <div className={`grow-1 bg-dark rounded align-self-stretch ${this.props.minimal?'d-none':''}`}/>
                        <Link to="/"><img id="header-logo-sm" alt="Website Logo" src={logo} height='60px' style={{height: '60px'}} className="bg-dark shadow-lg img-fluid border border-dark rounded"/></Link>
                        <div className="grow-1 bg-dark rounded align-self-stretch"/>
                        <button className={`btn navbutton-sm btn-dark px-3 shadow-md text-light ${this.props.nologin?'d-none':''}`} onClick={this.expand} title="Expand Login Menu"><i className={`bi ${this.state.LoggedIn?this.state.LoginExpanded?'bi-person-dash':'bi-person':this.state.LoginExpanded?'bi-chevron-up':'bi-box-arrow-in-right'}`} style={{fontSize:'1.25rem'}}/><span className="visually-hidden">Expand Login Menu</span></button>
                    </div>
                    <div className={this.state.LoginExpanded&&!this.state.LoggedIn?'':'d-none'}>
                        <form className="bg-dark rounded p-2" onSubmit={this.login}>
                            <label htmlFor="headerm-login-email" className="visually-hidden">E-Mail Address</label>
                            <div className="input-group mb-3">
                                <input id="headerm-login-email" type="text" name="EMail" className="form-control form-control-sm bg-light text-dark" placeholder="E-Mail Address" disabled={this.state.LoginLoading} value={this.state.EMail} onChange={this.inputChange}/>
                            </div>
                            <label htmlFor="headerm-login-password" className="visually-hidden">Password</label>
                            <div className="input-group mb-1">
                                <input id="headerm-login-password" type={`${this.state.PasswordHidden?'password':'text'}`} name="Password"  placeholder="Password" className="form-control form-control-sm bg-light text-dark" onChange={this.inputChange} value={this.state.Password} disabled={this.state.LoginLoading}/>
                                <button className="btn btn-sm btn-light " type="button" onClick={this.eye} title="Toggle wether password is hidden or not">
                                    <i className={`bi ${this.state.PasswordHidden?'bi-eye-slash-fill':'bi-eye-fill'}`}/>
                                </button>
                            </div>
                            <div className="form-check form-switch text-light mb-3">
                                <input name="RememberMe" type="checkbox" id="headerm-login-rememberme" className="form-check-input bg-primary" onChange={this.rm} checked={this.state.RememberMe}/>
                                <label className="form-check-label" htmlFor="headerm-login-rememberme">Remember Me</label>
                            </div>
                            <div className="d-grid gap-2">
                                <button type="submit" title="Login" className={`btn btn-sm btn-primary ${this.state.LoginLoading?'d-none':''}`} disabled={!this.state.LoginValid}>Login</button>
                                <button type="button" title="Loading..." className={`btn btn-sm btn-primary ${this.state.LoginLoading?'':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/> Loading...</button>
                                <div className="row g-2">
                                    <Link className="col d-grid" to="/signup"><button type="button" title="To Signup page" className="btn btn-sm btn-light"><i className="bi bi-people"/><span className="visually-hidden">To Signup page</span></button></Link>
                                    <Link className="col d-grid" to="/login"><button type="button" title="To Login page" className="btn btn-sm btn-light"><i className="bi bi-box-arrow-in-right"/><span className="visually-hidden">To Login page</span></button></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={this.state.LoginExpanded&&this.state.LoggedIn?'':'d-none'}>
                        <div className="bg-dark text-light p-2 rounded">
                            <div className={this.state.UserLoading?'d-none':''}>
                                <div className="d-flex flex-column align-items-center">
                                    {this.state.ActiveUserName}<br/>
                                    <small>{this.state.ActiveUserEmail}</small>
                                </div>
                                <button type="button" className='btn btn-block btn-sm btn-outline-light mt-3' onClick={this.logout}><i className="bi bi-box-arrow-left"/> Logout</button>
                            </div>
                            <div className={this.state.UserLoading?'':'d-none'}>
                                <span className="spinner-border spinner-border-sm"/> Loading user data...
                            </div>
                        </div>
                    </div>
                    <div className={this.state.MobileExpanded?'':'d-none'}>
                        <Link to="/"><button className="btn btn-dark p-1 navbutton-sm w-100 text-light"><i className="bi bi-house-door"/> Home</button></Link>
                        <Link to="/projects"><button className="btn btn-dark p-1 navbutton-sm w-100 text-light"><i className="bi bi-kanban"/> Projects</button></Link>
                        <Link to="/catplug" className={!this.state.Roles.includes("catplug")?'d-none':''}><button className="btn btn-dark p-1 navbutton-sm w-100 text-light"><i className="bi bi-plug"/> Catplug</button></Link>
                        <Link to="/impressum"><button className="btn btn-dark p-1 navbutton-sm w-100 text-light"><i className="bi bi-info-circle"/> Impressum</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}