import React, { Component } from 'react';
import { Textblock } from '../Textblock';
import { Header } from '../Header'
import WebService from '../../service/WebService';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export class EditPattern extends Component{
    static displayName="Editing interface for Patterns";

    constructor(props){
        super(props);

        this.state = {
            loggedIn: false,
            pattern: null,
            loading: false,
            edited: false,
            motoramount: 1,
            confirm: null,
            redirect: false
        };

        this.webservice = WebService.getInstance();

        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);

        this.deletePattern = this.deletePattern.bind(this);
        this.savePattern = this.savePattern.bind(this);

        this.changeVisDom = this.changeVisDom.bind(this);
        this.changeNameDescription = this.changeNameDescription.bind(this);
        this.changeRepeatBehavior = this.changeRepeatBehavior.bind(this);
        this.changemotor = this.changemotor.bind(this);
        this.addStep = this.addStep.bind(this);
        this.deleteStep = this.deleteStep.bind(this);
        this.changeStepLength = this.changeStepLength.bind(this);
        this.changeStepHold = this.changeStepHold.bind(this);
        this.changeMainInput = this.changeMainInput.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.deleteInput = this.deleteInput.bind(this);
        this.addInput = this.addInput.bind(this);
    }

    onLogin(roles){
        this.setState({
            loggedIn: true
        });
        this.webservice.getPattern(this.props.match.params.id)
        .then((pattern) => {
            console.log(pattern);
            this.setState({
                pattern: pattern,
                repeatinf: pattern.pattern.amountOfRepeats === null
            });
        })
        .catch(() => {});
    }

    onLogout(){
        this.setState({
            loggedIn: false
        });
    }

    componentDidMount(){
        this.webservice.addListeners(this.onLogin, this.onLogout);
        if (this.webservice.isLoggedIn()){
            this.onLogin(this.webservice.getRoles());
        } else {
            this.onLogout();
        }
    }

    componentWillUnmount(){
        this.webservice.removeListeners(this.onLogin, this.onLogout);
    }

    deletePattern(){
        this.webservice.deletePattern({
            id:this.props.match.params.id
        })
        .then( data => {
            this.setState({
                redirect: true
            })
        })
        .catch(err => {
            this.setState({
                confirm:null
            });
        });
    }

    savePattern(){
        this.setState({
            loading: true
        });
        this.webservice.updatePattern(this.state.pattern)
        .then( (data) => {
            this.setState({
                loading: false,
                edited: false
            });
        })
        .catch((err) => {
            this.setState({
                loading: false
            });
        });
    }

    changeVisDom(){
        this.setState((state) => {
            state.edited = true;
            state.pattern.visibleToController = !state.pattern.visibleToController;
            return state;
        });
    }

    changeNameDescription(event){
        event.persist();
        this.setState((state) => {
            state.edited = true;
            state.pattern.pattern[event.target.name] = event.target.value;
            return state;
        });
    }

    changeRepeatBehavior(data){
        if(data === 0){
            this.setState((state) => {
                state.edited = true;
                state.pattern.pattern.repeating = false;
                state.pattern.pattern.amountOfRepeats = null;
                return state;
            });
        } else if(data === -1){
            this.setState((state) => {
                state.edited = true;
                state.pattern.pattern.repeating = true;
                state.pattern.pattern.amountOfRepeats = null;
                return state;
            });
        } else if(data === -2){
            this.setState((state) => {
                state.edited = true;
                state.pattern.pattern.repeating = true;
                state.pattern.pattern.amountOfRepeats = 1;
                return state;
            });
        } else {
            data.persist();
            this.setState((state) => {
                state.edited = true;
                state.pattern.pattern.repeating = true;
                state.pattern.pattern.amountOfRepeats = data.target.value;
                return state;
            });
        }
    }

    changemotor(event){
        this.setState({
            motoramount: event.target.value
        });
    }

    addStep(){
        var step = {
            length: 1000,
            hold: false,
            vibrate: {
                inputs:[],
                mainInput:0
            }
        };
        for (var i = 0; i<this.state.motoramount; i++){
            step.vibrate.inputs.push(0.0);
        }
        this.setState((state) => {
            state.pattern.pattern.steps.push(step);
            state.edited = true;
            return state;
        });
    }

    deleteStep(id){
        this.setState((state) => {
            state.pattern.pattern.steps.splice(id, 1);
            state.edited = true;
            return state;
        });
    }

    changeStepLength(event){
        event.persist();
        var id = parseInt(event.target.id.split("-")[1]);
        this.setState((state) => {
            state.edited = true;
            state.pattern.pattern.steps[id].length = event.target.value;
            return state;
        });
    }

    changeStepHold(id){
        this.setState((state) => {
            state.edited = true;
            state.pattern.pattern.steps[id].hold = !state.pattern.pattern.steps[id].hold
            return state;
        });
    }

    changeMainInput(step, value){
        this.setState((state) => {
            state.edited = true;
            state.pattern.pattern.steps[step].vibrate.mainInput = value;
            return state;
        });
    }

    changeInput(event){
        event.persist();
        var step = parseInt(event.target.id.split("-")[1]);
        var input = parseInt(event.target.id.split("-")[2]);
        this.setState((state) => {
            state.edited = true;
            state.pattern.pattern.steps[step].vibrate.inputs[input] = event.target.value;
            return state;
        });
    }

    deleteInput(step, input){
        this.setState((state) => {
            state.pattern.pattern.steps[step].vibrate.inputs.splice(input,1);
            state.edited = true;
            return state;
        });
    }

    addInput(step){
        this.setState((state) => {
            state.edited = true;
            state.pattern.pattern.steps[step].vibrate.inputs.push(0.0);
            console.log(state.pattern.pattern.steps[step].vibrate.inputs);
            return state;
        });
    }

    render(){
        if(this.state.redirect){
            return (
                <Redirect to="../configure" />
            )
        }
        if(this.state.pattern == null){
            return (
                <span className='spinner-border mx-auto' />
            )
        }
        return (
            <div>
                <Header minimal />
                <div className="container-sm pt-5 text-light">
                    <div className={this.state.loggedIn?"d-none":""}>
                        <Textblock title="Error">
                            <p>You need to be logged in to use this area</p>
                        </Textblock>
                    </div>
                    <div className={this.state.loggedIn?"":"d-none"}>
                        <Textblock title="Edit Pattern">
                            <div className='d-flex mb-3'>
                                <Link to="../configure"><button className='btn btn-primary px-5' title="Exit"><i className='bi bi-arrow-left' /><span className='visually-hidden'>Exit</span></button></Link>
                                <div className='grow-1' />
                                <button className={`btn btn-primary px-5 me-2 ${this.state.loading?"d-none":""}`} title="Save" disabled={!this.state.edited} onClick={this.savePattern}><i className='bi bi-save' /><span className='visually-hidden'>Save</span></button>
                                <button className={`btn btn-primary px-5 me-2 ${this.state.loading?"":"d-none"}`} title="Loading" disabled><span className="spinner-border spinner-border-sm"></span><span className='visually-hidden'>Loading</span></button>
                                <button className={`btn btn-light px-5 ${this.state.confirm==="pat"?"d-none":""}`} title="delete" onClick={() => this.setState({confirm:"pat"})}><i className='bi bi-trash' /><span className='visually-hidden'>Delete</span></button>
                                <button className={`btn btn-light px-5 ${this.state.confirm==="pat"?"":"d-none"}`} title="confirm" onClick={this.deletePattern}><i className='bi bi-check-lg' /><span className='visually-hidden'>Confirm</span></button>
                            </div>
                        </Textblock>
                        <Textblock title="General Data">
                            <label htmlFor='editpatname'>Name:</label>
                            <input name="name" id="editpatname" type="text" className='form-control bg-light text-dark mt-1 mb-3' value={this.state.pattern.pattern.name} onChange={this.changeNameDescription}/>
                            
                            <label htmlFor='editpatdescription'>Description:</label>
                            <textarea name='description' id='editpatdescription' rows="3" className='form-control bg-light text-dark mt-1 mb-3 w-100' value={this.state.pattern.pattern.description} onChange={this.changeNameDescription}/>

                            <label htmlFor='editpatviscon' className='form-check-label'>Visible to Remote Controller:</label>
                            <div className='form-check form-switch text-light mt-1 mb-3'>
                                <input name="visibleToController" id="editpatviscon" type='checkbox' className='form-check-input bg-primary' checked={this.state.pattern.visibleToController} onChange={this.changeVisDom}/>
                            </div>

                            <span>Repeating:</span>
                            <div className='input-group mt-1 mb-3'>
                                <div className='input-group-text bg-light'>
                                    <input name="repeat" id="editpatreapeatno" type="radio" className='form-check-input bg-primary text-light' checked={!this.state.pattern.pattern.repeating} onChange={() => {this.changeRepeatBehavior(0)}}></input>
                                </div>
                                <div className='input-group-text bg-dark text-light'>
                                    <label htmlFor='editpatreapeatno'>Not Repeating</label>
                                </div>
                                <div className='input-group-text bg-light'>
                                    <input name="repeat" id="editpatreapeatinf" type="radio" className='form-check-input bg-primary text-light' checked={(this.state.pattern.pattern.repeating)&&(this.state.pattern.pattern.amountOfRepeats==null)} onChange={() => {this.changeRepeatBehavior(-1)}}></input>
                                </div>
                                <div className='input-group-text bg-dark text-light'>
                                    <label htmlFor='editpatreapeatinf'>Repeating Infinitely</label>
                                </div>
                                <div className='input-group-text bg-light'>
                                    <input name="repeat" id="editpatrepeatx" type='radio' className='form-check-input bg-primary text-light' checked={(this.state.pattern.pattern.repeating)&&(this.state.pattern.pattern.amountOfRepeats!=null)} onChange={() => {this.changeRepeatBehavior(-2)}}/>
                                </div>
                                <div className='input-group-text bg-dark text-light'>
                                    <label htmlFor='editpatrepeatx'>Repeating</label>
                                </div>
                                <input name="repeatcount" id="editpatrepeatcount" type="number" step="1" min="1" className='form-control bg-dark text-light' disabled={(!this.state.pattern.pattern.repeating)||(this.state.pattern.pattern.numberOfRepeats===null)} value={this.state.pattern.pattern.amountOfRepeats===null?"":this.state.pattern.pattern.amountOfRepeats} onChange={this.changeRepeatBehavior} />
                                <div className='input-group-text bg-dark text-light'>
                                    <label htmlFor='editpatrepeatx'>Times</label>
                                </div>
                            </div>
                        </Textblock>
                        <Textblock title="Steps">
                            <label htmlFor='editpatamountmotors'>Amount of motors:</label>
                            <input name="motors" type="number" min="1" step="1" className='form-control bg-light text-dark mt-1 mb-3' value={this.state.motoramount} onChange={this.changemotor} />
                            <div className='px-4'>
                                {this.state.pattern.pattern.steps.map(
                                 (step, index) => {return (
                                    <Textblock title={`Step ${index+1}:`} key={index}>
                                    <div className='d-flex mt-1 mb-3'>
                                        <label htmlFor={`editpatlen-${index}`} className="align-self-center">Length in ms:</label>
                                        <input id={`editpatlen-${index}`} type="number" min="0" max="1" step="0.05" className='form-control bg-light text-dark w-a ms-2 me-2' value={step.length} onChange={this.changeStepLength}/>
                                        <label htmlFor={`editpathold-${index}`} className="align-self-center">Hold afterwards:</label>
                                        <input id={`editpathold-${index}`} type="checkbox" className='form-check-input bg-primary text-light ms-2 me-2 align-self-center' checked={step.hold} onChange={() => {this.changeStepHold(index)}}/>
                                        <div className='grow-1'/>
                                        <button title="Delete Step" className={`btn btn-light px-5 ${this.state.confirm===index?"d-none":""}`} onClick={()=>{this.setState({confirm:index})}}><i className='bi bi-trash'/><span className='visually-hidden'>Delete Step</span></button>
                                        <button title="Confirm" className={`btn btn-light px-5 ${this.state.confirm===index?"":"d-none"}`} onClick={() => {this.deleteStep(index)}}><i className='bi bi-check-lg' /><span className='visually-hidden'>Confirm</span></button>
                                    </div>
                                    <div className='px-4'>
                                        {step.vibrate.inputs.map((input, index2) => {
                                            return (
                                                <div key={index+"-"+index2} className="mb-1">
                                                    <label htmlFor={`primaryinput-${index}-${index2}`} className='visually-hidden'>Is main input?</label>
                                                    <label htmlFor={`input-${index}-${index2}`} className='visually-hidden'>Input Strength</label>
                                                    <label htmlFor={`inputslider-${index}-${index2}`} className='visually-hidden'>Input Strength slider</label>
                                                    <div className='input-group'>
                                                        <div className='input-group-text bg-light'>
                                                            <input id={`primaryinput-${index}-${index2}`} type="radio" className='form-check-input bg-primary' checked={step.vibrate.mainInput===index2} title="Is main input?" onChange={() => {this.changeMainInput(index, index2)}}/>
                                                        </div>
                                                        <div className='input-group-text bg-light grow-1'>
                                                            <input id={`inputslider-${index}-${index2}`} className='form-range text-dark' type='range' min='0' max='1' step="0.05" title="Input Strength slider" value={input} onChange={this.changeInput}/>
                                                        </div>
                                                        <input id={`input-${index}-${index2}`} type="number" min="0" max="1" step="0.05" className="form-control bg-light text-dark w-a" title="Input Strength" value={input} onChange={this.changeInput}/>
                                                        {this.state.confirm!==`${index}-${index2}`?
                                                        <button className='btn btn-light' title="delete" onClick={() => {this.setState({confirm: `${index}-${index2}`});}}><i className='bi bi-trash' /><span className='visually-hidden'>delete</span></button>:
                                                        <button className='btn btn-light' title="confirm" onClick={() => {this.deleteInput(index,index2)}}><i className='bi bi-check-lg' /><span className='visually-hidden'>confirm</span></button>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <button className='btn btn-outline-light w-100' onClick={() => {this.addInput(index)}}><i className='bi bi-plus' />Add Input</button>
                                    </div>
                                    </Textblock>
                                 );}   
                                )}
                                <button className='btn btn-light w-100' onClick={this.addStep}><i className='bi bi-plus' /> Add Step</button>
                            </div>
                        </Textblock>
                    </div>
                </div>
            </div>
        );
    }
}