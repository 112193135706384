import React, { Component } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import { Impressum } from './components/Impressum';
import { Projects } from './components/Projects';
import { Login } from './components/Login';
import { ForgotPassword } from './components/ForgotPassword';
import { EmailHelper } from './components/EmailHelper';
import { SignUp } from './components/SignUp';
import { CatPlug } from './components/Catplug/CatPlug';

import './custom.scss'

export default class App extends Component {
  static displayName = App.name;

  render () {
    /*return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
      </Layout>
    );*/
      return (
        <div className="h-100">
          <div className="">
            <Route exact path='/' component={Home} />
            <Route path='/projects' component={Projects}/>
            <Route path='/impressum' component={Impressum} />
            <Route path='/catplug' component={CatPlug} />
            <Route path='/login' component={Login} />
            <Route path='/forgotpassword' component={ForgotPassword} />
            <Route path='/emailhelper' component={EmailHelper} />
            <Route path='/signup/' component={SignUp} />
          </div>
        </div>
      );
  }
}
