import React,{Component} from "react";
import { Textblock } from "./Textblock";

export class TextblockNSFW extends Component{
    static displayName = TextblockNSFW.name;

    constructor(props){
        super(props);

        this.state = {
            Revealed: false
        };

        this.reveal = this.reveal.bind(this);
    }

    reveal(){
        this.setState({
            Revealed: true
        });
    }

    render(){
        return(
            <Textblock title={this.props.title}>
                {this.state.Revealed?this.props.children:(
                <div className="d-flex align-items-end">
                    <p className="grow-1">This content might not be appropriate for all audiences, please click the button to reveal it.</p>
                    <div>
                        <button className="btn btn-primary px-5 ml-2" onClick={this.reveal}>Reveal</button>
                    </div>
                </div>
                )}
            </Textblock>
        );
    }
}