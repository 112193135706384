import React, {Component} from 'react';
import { Header } from '../Header';
import { ButtplugClient, ButtplugEmbeddedConnectorOptions, buttplugInit, ButtplugWebsocketConnectorOptions } from 'buttplug';
import { ButtplugDeviceControlInterface } from './ButtplugDeviceControlInterface';
import { Textblock } from '../Textblock';
import { DeviceWrapper } from './DeviceWrapper';
import { Redirect } from 'react-router-dom';
import { NoSleepButton } from '../NoSleepButton';

export class CatPlugLocal extends Component{
    static displayName = "Buttplug Interface";

    constructor(props){
        super(props);
        this.state = {
            Connected: false, 
            Devices: [],
            BrowserWarning: !(window.chrome),
            RSPortValue: "12345",
            Redirect: null
        };

        this.connector = null;
        this.client = null;

        this.connect = this.connect.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    async componentDidMount(){

        await buttplugInit();

        this.connector = new ButtplugEmbeddedConnectorOptions();
        if(this.props.match.params.rsport!==undefined){
            this.connector = new ButtplugWebsocketConnectorOptions();
            this.connector.address = "ws://localhost:"+this.state.RSPort;
        }
        this.client = new ButtplugClient("Catplug local");

        this.client.addListener("deviceadded", (device) => {
            this.setState(state => {
                state.Devices = [...state.Devices, new DeviceWrapper(device)];
                return state
            });
        });
        this.client.addListener("deviceremoved", (device) => {
            if(!this.state.Connected) return;
            this.state.Devices.filter(dev => dev._device===device).forEach(e => e.beforeDisconnect());
            this.setState(state => {
                state.Devices = state.Devices.filter(dev => dev._device!==device);
                return state;
            });
        });

        try {
            await this.client.connect(this.connector);
            this.setState({
                Connected: true,
                Devices: this.client.Devices.map(e => new DeviceWrapper(e))
            })
        } catch (e){
            this.setState({
                Error: true
            });
            console.log(e);
            this.client=null;
        }
    }

    async connect(){
        
        // Now that everything is set up, we can scan.
        try{
            await this.client.startScanning();
        } catch(e){

        }
    }

    componentWillUnmount(){
        if(this.client !== null){
            this.client.disconnect();
            this.setState({
                Connected:false
            });
        }
    }

    inputChange(event){
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    submit(event){
        event.preventDefault();
        this.setState({
            Redirect: "/catplug/localrs/"+this.state.RSPortValue
        });
    }

    render(){
        if(this.state.Redirect!==null){
            return(
                <Redirect push to={this.state.Redirect} />
            );
        }
        return(
            <div>
                <Header minimal/>
                <div className="container-sm pt-5 text-light">
                    <h1 className="text-center mb-5">Catplug Local</h1>
                    <div className='w-100 d-flex mb-2'>
                        <div className='grow-1' />
                        <NoSleepButton/>
                    </div>
                    {this.state.Devices.map((dev, i) => (
                        <ButtplugDeviceControlInterface key={i} index={i} device={dev}/>
                    ))}
                    <div className={this.state.Devices.length===0?'':'d-none'}>
                        <Textblock title="No Devices">
                            There are currently no devices connected, please connect at least one device to begin.
                        </Textblock>
                        <div className={this.state.BrowserWarning&&this.state.Devices.length===0&&this.props.match.params.rsport===undefined?'':'d-none'}>
                            <Textblock title="Warning">
                                This Browser may not support Web Bluetooth. If adding devices doesnt work this might be case.
                            </Textblock>
                        </div>
                    </div>
                    <div className={this.state.Error?'':'d-none'}>
                        <Textblock title="Error">
                            Connection couldnt be established, please reload the page to try again
                        </Textblock>
                    </div>
                    <div className="d-grid">
                        <button className="btn btn-outline-primary p-1 mb-5" onClick={this.connect} disabled={!this.state.Connected}>Connect more Devices</button>
                    </div>
                    <div className={this.state.Devices.length===0&&this.props.match.params.rsport===undefined?'':'d-none'}>
                        <Textblock title="Connect to Local Server">
                            <form className="form-inline" onSubmit={this.submit}>
                                <div className="grow-1">You can connect to a local server. For example a local instance of Intiface</div>
                                <label className="visually-hidden" htmlFor="catplug-local-form-port">Port of Local server</label>
                                <div className="input-group">
                                    <span className="input-group-text bg-light text-dark">localhost:</span>
                                    <input id="catplug-local-form-port" name="RSPortValue" type="text" className="form-control bg-light text-dark" placeholder="Port of local server" value={this.state.RSPortValue} onChange={this.inputChange}/>
                                    <button type="submit" className="btn btn-primary" title="Switch"><i className="bi bi-arrow-right"/><span className="visually-hidden">switch</span></button>
                                </div>
                            </form>
                        </Textblock>
                    </div>
                </div>
            </div>
        );
    }
}