import React, {Component} from 'react';
import {Header} from './Header'
import WebService from '../service/WebService';
import { FunctionWithLogo } from './FunctionWithLogo';

export class ForgotPassword extends Component{
    static displayName = "Forgot Password"

    constructor(props){
        super(props);

        this.state = {
            EMail: "",
            Loading: false,
            Valid: false,
            Success: false
        };

        this.inputChange = this.inputChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

        this.webservice = WebService.getInstance();
    }

    inputChange(event){
        this.setState({
            [event.target.name]:event.target.value
        }, () => {
            var valid = true;
            if (this.state.EMail === "") valid = false;
            this.setState({
                Valid: valid
            });
        });
    }

    resetPassword(event){
        event.preventDefault();
        this.setState({
            Loading:true
        });
        this.webservice.requestPasswordReset({
            eMail: this.state.EMail
        }).then(() => {
            this.setState({
                Success:true
            });
        }).catch(err => {

        }).finally( () => {
            this.setState({
                Loading:false
            });
        });
    }

    render(){
        return(
            <div>
                <Header minimal/>
                <div className="container-sm pt-5 text-light">
                    <div style={{marginTop: '10%'}}>
                        <FunctionWithLogo title="Password Reset">
                            <form className={`${this.state.Success?'d-none':''}`} onSubmit={this.resetPassword}>
                                <label htmlFor="reset-password-email" className="visually-hidden">E-Mail Adress</label>
                                <div className="input-group mb-3 mt-2">
                                    <input id="reset-password-email" name="EMail" type="text" className="form-control text-dark bg-light" placeholder="E-Mail" value={this.state.EMail} onChange={this.inputChange} disabled={this.state.Loading}/>
                                </div>
                                <button type="submit" className={`btn btn-primary ${this.state.Loading?'d-none':''}`} disabled={!this.state.Valid}>Request Reset</button>
                                <button type="button" className={`btn btn-primary ${this.state.Loading?'':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/>Loading...</button>
                            </form>
                            <div className={`${this.state.Success?'':'d-none'}`}>
                                If we have an account registered under {this.state.EMail} you will receive an E-Mail containing information to reset your password
                                shortly. If you cannot find it make sure to check your spam folder as well.
                            </div>
                        </FunctionWithLogo>
                    </div>
                </div>
            </div>
        );
    }
}