import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Header} from '../Header';
import { ButtplugClient, ButtplugEmbeddedConnectorOptions, buttplugInit, ButtplugWebsocketConnectorOptions } from 'buttplug';
import { Textblock } from '../Textblock';
import WebService from '../../service/WebService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { RCWrapper } from './RCWrapper';
import { MockDevice } from './MockDevice';
import { NoSleepButton } from '../NoSleepButton';

export class CatPlugRemote extends Component{
    static displayName = "Buttplug Interface";

    constructor(props){
        super(props);

        this.state = {
            LoggedIn: false,
            Connected: false,
            BrowserWarning: !(window.chrome),
            Devices: [],
            DomName: "Noone yet",
            DomLink: "To be generated",
            Stop: false,
            StopTimout: false,
            RSPortValue: "12345",
            Redirect: null
        }

        

        this.connector = null;
        this.client = null;
        this.webservice = WebService.getInstance();
        this.signalrConnection = null;
        
        this.lastIndex = 0;

        this.pasteToCB = this.pasteToCB.bind(this);
        this.regenLink = this.regenLink.bind(this);
        this.connect = this.connect.bind(this);
        this.stop = this.stop.bind(this);
        this.receiveCommand = this.receiveCommand.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.submit = this.submit.bind(this);

        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    async onLogin(roles){
        this.setState({
            LoggedIn: true
        });

        await buttplugInit();

        this.connector = new ButtplugEmbeddedConnectorOptions();
        if(this.props.match.params.rsport!==undefined){
            this.connector = new ButtplugWebsocketConnectorOptions();
            this.connector.address = `ws://localhost:${this.state.RSPort}`;
        }
        this.client = new ButtplugClient("Catplug Remote");

        this.client.addListener("deviceadded", (device) => {
            this.setState(state => {
                state.Devices = [...state.Devices, new RCWrapper(device, this.signalrConnection, this.lastIndex++)];
                return state
            },() => {
                this.sendDeviceList();
            });
        });

        this.client.addListener("deviceremoved", (device) => {
            if(!this.state.Connected) return;
            this.state.Devices.filter(dev => dev._device===device).forEach(e => e.beforeDisconnect());
            this.setState(state => {
                state.Devices =  state.Devices.filter(dev => dev._device!==device);
                return state;
            },() => {
                this.sendDeviceList();
            });
        });

        var user = await this.webservice.getUser();
        this.subName = user.userName;

        this.signalrConnection = new HubConnectionBuilder().withUrl("/Api/Hubs/PlugHub", {
            accessTokenFactory: () => this.webservice._token
        }).build();
        this.signalrConnection.on("receiveCommand", this.receiveCommand);

        this.signalrConnection.start().then(async () => {
            this.signalrConnection.invoke("Initialize");

            try {
                await this.client.connect(this.connector);
                this.setState({
                    Connected: true,
                    Devices: this.client.Devices.map(e => new RCWrapper(e, this.signalrConnection, this.lastIndex++))
                })
            } catch (e){
                this.setState({
                    Error: true
                });
                this.client=null;
            }
        }).catch(e => {
            this.setState({
                Error: true
            });
        });
    }

    onLogout(){
        this.setState({
            LoggedIn: false
        });
        if(this.signalrConnection !== null){
            this.signalrConnection.stop();
        }
        if(this.client !== null){
            this.client.disconnect();
            this.setState({
                Connected:false
            });
        }
    }

    componentDidMount(){
        this.webservice.addListeners(this.onLogin, this.onLogout);
        if (this.webservice.isLoggedIn()){
            this.onLogin();
        } else {
            this.onLogout();
        }
    }

    componentWillUnmount(){
        this.webservice.removeListeners(this.onLogin, this.onLogout);
        if(this.signalrConnection !== null){
            this.signalrConnection.stop();
        }
        if(this.client !== null){
            this.client.disconnect();
            this.setState({
                Connected:false
            });
        }
    }

    receiveCommand(dev, command, args){
        //console.log(dev);
        //console.log(command);
        //console.log(args);
        if(dev !== null){
            this.state.Devices.filter(d => d._index===dev)[0].receiveCommand(command, args);
            return;
        }
        switch(command){
            case "setIdentifyer":
                this.setState({
                    DomLink: `${window.location.protocol}//${window.location.hostname}/catplug/control/${encodeURIComponent(this.subName)}/${encodeURIComponent(args)}`
                });
                break;
            case "setDomName":
                this.setState({
                    DomName: args
                });
                break;
            case "sendDeviceList":
                this.sendDeviceList();
                break;
            default: 
                console.log(command);
                console.log(args);
        }
    }

    sendDeviceList(){
        var list = [];
        this.state.Devices.forEach(e => {
            list.push(new MockDevice(e));
        });
        this.signalrConnection.invoke("SendToDom",null ,"setDeviceList", list);;
    }

    pasteToCB(){
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText(this.state.DomLink);
            }
        }).catch(e => {
            var ct = document.querySelector("#catplug-remote-domlink");
            ct.select();
            document.execCommand("copy");
        });
    }

    regenLink(){
        this.signalrConnection.invoke("SetNewIdentifyer");
        this.setState({
            DomName: "Noone yet"
        });
    }

    async connect(){
        try{
            await this.client.startScanning();
        } catch(e){

        }
    }

    stop(){
        if(this.state.Stop){
            this.state.Devices.forEach(d => d.fstop(false));
            this.setState({
                Stop:false
            });
            return;
        }
        this.state.Devices.forEach(d => d.fstop(true));
        this.setState({
            Stop:true,
            StopTimout:true
        });
        setTimeout(() => {
            this.setState({
                StopTimout:false
            });
        }, 3000);
    }

    inputChange(event){
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    submit(event){
        event.preventDefault();
        this.setState({
            Redirect: "/catplug/remoters/"+this.state.RSPortValue
        });
    }

    render(){
        if(this.state.Redirect!==null){
            return(
                <Redirect push to={this.state.Redirect} />
            );
        }
        return(
            <div>
                <Header minimal/>
                <div className="container-sm pt-5 text-light">
                    <h1 className="text-center mb-5">Catplug Remote</h1>
                    <div className='w-100 d-flex mb-2'>
                        <div className='grow-1' />
                        <NoSleepButton/>
                    </div>
                    <div className={this.state.LoggedIn?'':'d-none'}>
                        <div className={this.state.BrowserWarning&&this.state.Devices.length===0&&this.props.match.params.rsport===undefined?'':'d-none'}>
                            <Textblock title="Warning">
                                This Browser may not support Web Bluetooth. If adding devices doesnt work this might be case.
                            </Textblock>
                        </div>
                        <div className={this.state.Error?'':'d-none'}>
                            <Textblock title="Error">
                                Connection couldnt be established, please reload the page to try again
                            </Textblock>
                        </div>
                        <Textblock title="Your Interface">
                            <div className="row align-items-center mb-5">
                                <div className="col-12 col-sm-6">
                                    You are being Controlled by:<br/>{this.state.DomName}
                                </div>
                                <div className="col-8 col-sm-4">
                                    <label className="mb-2" htmlFor="catplug-remote-domlink">Share this url:</label>
                                    <div className="input-group">
                                        <button type="button" title="Copy to Clipboard" className="btn btn-sm btn-light" onClick={this.pasteToCB}><i className="bi bi-clipboard-check"/><p className="visually-hidden">Copy to Clipboard</p></button>
                                        <input id="catplug-remote-domlink" value={this.state.DomLink} readOnly className="form-control form-control-sm text-dark bg-light"/>
                                        <button type="button" title="Generate new Link" className="btn btn-sm btn-light" onClick={this.regenLink}><i className="bi bi-shuffle"/><p className="visually-hidden">Generate new Link</p></button>
                                    </div>
                                </div>
                                <div className="col-4 col-sm-2">
                                    Devices: {this.state.Devices.length}
                                    <button type="button" className="btn btn-sm btn-outline-primary w-100 mt-2" onClick={this.connect} disabled={!this.state.Connected}>Add Device</button>
                                </div>
                            </div>
                            <div className="d-grid">
                                <button className="btn btn-primary p-1" onClick={this.stop} style={{height: '220px', fontSize:'50px'}} disabled={this.state.Stop && this.state.StopTimout}>{this.state.Stop?'Resume':'Stop'}</button>
                            </div>
                        </Textblock>
                        <div className={this.state.Devices.length===0&&this.props.match.params.rsport===undefined?'':'d-none'}>
                            <Textblock title="Connect to Local Server">
                                <form className="form-inline" onSubmit={this.submit}>
                                    <div className="grow-1">You can connect to a local server. For example a local instance of Intiface</div>
                                    <label className="visually-hidden" htmlFor="catplug-local-form-port">Port of Local server</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-light text-dark">localhost:</span>
                                        </div>
                                        <input id="catplug-local-form-port" name="RSPortValue" type="text" className="form-control bg-light text-dark" placeholder="Port of local server" value={this.state.RSPortValue} onChange={this.inputChange}/>
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-primary" title="Switch"><i className="bi bi-arrow-right"/><span className="visually-hidden">switch</span></button>
                                        </div>
                                    </div>
                                </form>
                            </Textblock>
                        </div>
                    </div>
                    <div className={this.state.LoggedIn?'d-none':''}>
                        <Textblock title="Error">
                            You need to be Logged in to use this functionality
                        </Textblock>
                    </div>
                </div>
            </div>
        );
    }
}