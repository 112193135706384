import {DeviceWrapper} from './DeviceWrapper'

export class RCWrapper extends DeviceWrapper{
    constructor(device, connection, index){
        super(device);
        this._connection = connection;
        this._index = index;
        this._stopped = false;
    }

    callback(val){
        this._connection.invoke("sendToDom", this._index , "setBatteryLevel", val);
    }

    waitCallback(){
        this._connection.invoke("sendToDom", this._index, "callWaitCallback", null);
    }

    endCallback(){
        this._connection.invoke("sendToDom", this._index, "callEndCallback", null);
    }

    fstop(bool){
        this.stop();
        this._stopped = bool;
    }

    receiveCommand(command, args){
        switch(command){
            case "stop":
                this.stop();
                break;
            case "vibrate":
                if(this._stopped) break;
                this.vibrate(args);
                break;
            case "startCheckingBattery":
                this.startCheckingBattery(this.callback);
                break;
            case "stopCheckingBattery":
                this.stopCheckingBattery(this.callback);
                break;
            case "playPattern":
                if(this._stopped) break;
                this.playPattern(args, this.waitCallback, this.endCallback);
                break;
            case "nextPatternStep":
                if(this._stopped) break;
                this.nextPatternStep();
                break;
            default:
                console.log(command);
                console.log(args);
                break;
        }
    }
}