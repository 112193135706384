import React, {Component} from 'react';
import WebService from '../service/WebService';
import { Redirect } from 'react-router-dom';
import { FunctionWithLogo } from './FunctionWithLogo';

export class ResetPassword extends Component{
    static displayName = "Login"

    constructor(props){
        super(props);

        this.PasswordTimeoutID = null;
        this.webservice = WebService.getInstance();

        this.state = {
            Password: "",
            PasswordHidden: true,
            Error: false,
            PasswordErr: Array(4).fill(false),
            Loading: false,
            Valid: false,
            Redirect: false,
        };

        this.resetPassword = this.resetPassword.bind(this);
        this.eye = this.eye.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.passwordValidate = this.passwordValidate.bind(this);
    }

    resetPassword(event){
        event.preventDefault();
        if(this.PasswordTimeoutID !== null) clearTimeout(this.PasswordTimeoutID);
        this.setState({
            Loading: true
        });
        this.webservice.resetPassword({
            eMail: decodeURIComponent(this.props.match.params.email),
            token: decodeURIComponent(this.props.match.params.token),
            newPassword: this.state.Password
        }).then(res => {
            this.setState({
                Redirect:"/emailhelper/success/resetpassword",
                Loading: false
            });
        }).catch(err => {
            this.setState({
                Error: true,
                Loading: false
            });
        }).finally(() => { });
        this.setState({
            Error: false,
            Password: "",
            PasswordErr: Array(4).fill(false)
        });
    }

    inputChange(event){
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            var res = true;
            if(this.state.Password === "") res = false;
            if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(this.state.Password)) res = false;
            this.setState({
                Valid: res
            });
            if(this.PasswordTimeoutID !== null) clearTimeout(this.PasswordTimeoutID);
            this.PasswordTimeoutID = setTimeout(() => this.passwordValidate(), 500);
        });
    }

    passwordValidate(){
        this.PasswordTimeoutID = null;
        var passwordErr = Array(4);
        passwordErr[0] = !/[\d]/.test(this.state.Password);
        passwordErr[1] = !/.{8,}/.test(this.state.Password);
        passwordErr[2] = !/[a-z]/.test(this.state.Password);
        passwordErr[3] = !/[A-Z]/.test(this.state.Password);
        if(this.state.Password === "") passwordErr = Array(4).fill(false);
        this.setState({
            PasswordErr: passwordErr
        })
    }
    
    eye(){
        this.setState({
            PasswordHidden: !this.state.PasswordHidden
        });
    }

    render(){
        if(this.state.Redirect){
            return <Redirect to={this.state.Redirect}/>
        }
        return (
            <FunctionWithLogo title="Password Reset">
                <form onSubmit={this.resetPassword}>
                        <label htmlFor="reset-password-password" className="visually-hidden">New Password</label>
                        <div className="input-group mt-2">
                            <input id="reset-password-password" type={this.state.PasswordHidden?'password':'text'} name="Password" className="form-control bg-light text-dark" placeholder="New Password" value={this.state.Password} onChange={this.inputChange} disabled={this.state.Loading}/>
                            <button type="button" className="btn btn-light" onClick={this.eye} title="Toggle wether password is hidden or not">
                                <i className={`bi ${this.state.PasswordHidden?'bi-eye-slash-fill':'bi-eye-fill'}`}/>
                            </button>
                        </div>
                        <small className={`form-text text-danger ${this.state.Error?'':'d-none'}`}>Password could not be reset. This link may not be valid anymore.</small>
                        <small className={`form-text text-danger ${this.state.PasswordErr[0]?'':'d-none'}`}>Password requires a digit (0-9)</small>
                        <small className={`form-text text-danger ${this.state.PasswordErr[1]?'':'d-none'}`}>Password requires at least 8 letters</small>
                        <small className={`form-text text-danger ${this.state.PasswordErr[2]?'':'d-none'}`}>Password requires a lowercase letter</small>
                        <small className={`form-text text-danger ${this.state.PasswordErr[3]?'':'d-none'}`}>Password requires an uppercase letter</small>
                        <button type="submit" className={`btn btn-primary px-3 mr-3 mt-3 ${this.state.Loading?'d-none':''}`} disabled={!this.state.Valid}>Reset Password</button>
                        <button type="button" className={`btn btn-primary px-3 mr-3 mt-3 ${this.state.Loading?'':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/> Loading...</button>
                    </form>
            </FunctionWithLogo>
        );
    }
}