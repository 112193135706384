import React, { Component } from 'react';
import nosleep from '@uriopass/nosleep.js';

export class NoSleepButton extends Component {
    static displayName = NoSleepButton.name;

    constructor(props){
        super(props);

        this.state = {
            active: false
        }

        this.nosleep = new nosleep();

        this.buttonClick = this.buttonClick.bind(this);
    }

    buttonClick(){
        if(this.state.active){
            this.nosleep.disable();
        } else {
            this.nosleep.enable();
        }
        this.setState({
            active: !this.state.active
        });
    }
    
    render(){
      return (
          <button className={`btn ${this.state.active?"btn-light":"btn-outline-light"}`} onClick={this.buttonClick}>Stay Awake</button>
      );
    }
  }