import React, {Component} from 'react';
import { Textblock } from './Textblock';
import logo from '../content/C58-Light.png';

export class FunctionWithLogo extends Component{
    static displayName = FunctionWithLogo.name;

    render(){
        return (
            <Textblock title={this.props.title}>
                <div className="d-flex align-items-center">
                    <img id="logo" alt="Website Logo" src={logo} className="bg-secondary img-fluid rounded me-5 d-none d-sm-block align-self-start" height='300' width='300'/>
                    <div className="grow-1">
                        {this.props.children}
                    </div>
                    <div className="me-5 d-none d-sm-block"/>
                </div>
            </Textblock>
        );
    }
}