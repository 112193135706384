import React, {Component} from 'react';
import {Header} from './Header'
import { Redirect, Link } from 'react-router-dom';
import WebService from '../service/WebService';
import { FunctionWithLogo } from './FunctionWithLogo';

export class Login extends Component{
    static displayName = "Login"

    constructor(props){
        super(props);
        this.state = {
            EMail: "",
            Password: "",
            RememberMe: false,
            PasswordHidden: true,
            Error: false,
            Redirect: false,
            Valid: false,
            Loading: false
        }

        this.webservice = WebService.getInstance();

        this.eye = this.eye.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.login = this.login.bind(this);
        this.rm = this.rm.bind(this);
    }

    eye(){
        this.setState({
            PasswordHidden: !this.state.PasswordHidden
        });
    }

    inputChange(event){
        this.setState({
            [event.target.name]:event.target.value
        }, () =>
        {
            var valid = true;
            if(this.state.EMail === "") valid = false;
            if(this.state.Password === "") valid = false;
            this.setState({
                Valid: valid
            });
        });
    }

    rm(){
        this.setState({
            RememberMe: !this.state.RememberMe
        });
    }

    login(event){
        event.preventDefault();
        this.setState({
            Loading: true
        });
        this.webservice.login({
            eMail: this.state.EMail,
            password: this.state.Password,
            rememberMe: this.state.RememberMe
        }).then (res => {
            if(res.token === undefined) {
                this.setState({
                    Error: true,
                    Loading: false
                })
                return;
            };
            this.setState({
                Redirect: "/",
                Loading: false
            });
        }).catch(() => {
            this.setState({
                Error: true,
                Loading: false
            });
        })
        .finally(() => {});
        this.setState({
            Password:"",
            Valid: false,
            Error:false
        });
    }

    render(){
        if(this.state.Redirect){
            return <Redirect to={this.state.Redirect}/>
        }
        return (
            <div>
                <Header minimal nologin/>
                <div className="container-sm pt-5 text-light">
                    <div style={{marginTop: '10%'}}>
                        <FunctionWithLogo title="Login">
                            <form onSubmit={this.login}>
                                <label htmlFor="login-email" className="visually-hidden">E-Mail Address</label>
                                <div className="input-group mb-3 mt-2">
                                    <input name="EMail" id="login-email" type="text" placeholder="E-Mail" className="form-control bg-light text-dark" onChange={this.inputChange} value={this.state.EMail} disabled={this.state.Loading}/>
                                </div>
                                <label htmlFor="login-password" className="visually-hidden">Password</label>
                                <div className="input-group">
                                    <input name="Password" id="login-password" type={`${this.state.PasswordHidden?'password':'text'}`} placeholder="Password" className="form-control bg-light text-dark" onChange={this.inputChange} value={this.state.Password} disabled={this.state.Loading}/>
                                    <button className="btn btn-light" type="button" onClick={this.eye} title="Toggle wether password is hidden or not">
                                        <i className={`bi ${this.state.PasswordHidden?'bi-eye-slash-fill':'bi-eye-fill'}`}/>
                                    </button>
                                </div>
                                <div className="form-check form-switch text-light mt-2">
                                    <input name="RememberMe" type="checkbox" id="login-rememberme" className="form-check-input bg-primary" onChange={this.rm} checked={this.state.RememberMe}/>
                                    <label className="form-check-label" htmlFor="login-rememberme">Remember Me</label>
                                </div>
                                <small className={`form-text text-danger ${this.state.Error?'':'d-none'}`}>E-Mail or Password not correct</small>
                                <div className='d-none d-sm-block'>
                                    <button type="submit" className={`btn btn-primary px-3 me-3 mt-3 ${this.state.Loading?'d-none':'d-inline'}`} disabled={!this.state.Valid}>Login</button>
                                    <button type="button" className={`btn btn-primary px-3 me-3 mt-3 ${this.state.Loading?'d-inline':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/> Loading...</button>
                                    <Link to="/forgotpassword"><button className="btn btn-outline-primary px-3 d-inline me-3 mt-3">Forgot Password</button></Link>
                                    <Link to="/signup"><button className="btn btn-outline-primary px-3 d-inline mt-3" title="To Sign up Page">Sign Up<span className="visually-hidden">To Sign up Page</span></button></Link>
                                </div>
                                <div className='d-grid gap-2 d-sm-none mt-3'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <button type="submit" className={`btn btn-primary w-100 py-2 ${this.state.Loading?'d-none':'d-inline'}`} disabled={!this.state.Valid}>Login</button>
                                            <button type="button" className={`btn btn-primary w-100 py-2 ${this.state.Loading?'d-inline':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/> Loading...</button>
                                        </div>
                                    </div>
                                    <div className='row g-2 align-content-stretch'>
                                        <div className='col-6'>
                                            <Link to="/forgotpassword"><button className="btn btn-outline-primary w-100">Forgot Password</button></Link>
                                        </div>
                                        <div className='col-6'>
                                            <Link to="/signup"><button className="btn btn-outline-primary w-100 h-100" title="To Sign up Page">Sign Up<span className="visually-hidden">To Sign up Page</span></button></Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FunctionWithLogo>
                    </div>
                </div>
            </div>
        );
    }
}