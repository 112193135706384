import React, { Component } from 'react';
import { Textblock } from '../Textblock';
import { Header } from '../Header'
import WebService from '../../service/WebService';
import { Link } from 'react-router-dom';

export class PatternList extends Component{
    static displayName="List of Patterns"

    constructor(props){
        super(props);

        this.state = {
            loggedIn: false,
            patterns: [],
            newLoading: false
        }

        this.webservice = WebService.getInstance();

        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);

        this.createNew = this.createNew.bind(this);
    }

    onLogin(roles){
        this.setState({
            loggedIn: true
        });
        this.webservice.getMyPatterns().then((data)=> {
            this.setState({
                patterns: data
            })
        }).catch();
    }

    onLogout(){
        this.setState({
            loggedIn: false,
            patterns: []
        });
    }

    componentDidMount(){
        this.webservice.addListeners(this.onLogin, this.onLogout);
        if (this.webservice.isLoggedIn()){
            this.onLogin(this.webservice.getRoles());
        } else {
            this.onLogout();
        }
    }

    componentWillUnmount(){
        this.webservice.removeListeners(this.onLogin, this.onLogout);
    }

    createNew(){
        this.setState({
            newLoading: true
        })
        this.webservice.getPattern(null)
        .then((newPattern) => {
            if (this.state.loggedIn) this.webservice.getMyPatterns()
            .then((data) => {
                this.setState({
                    patterns: data,
                    newLoading: false
                });
            })
            .catch(()=>{});
        })
        .catch((err)=>{
            this.setState({
                newLoading:false
            })
        });
    }

    edit(id){
        console.log(id);
    }

    render(){
        return (
            <div>
                <Header/>
                <div className="container-sm pt-5 text-light">
                    <div className={this.state.loggedIn?"d-none":""}>
                        <Textblock title="Error">
                            <p>You need to be logged in to use this area</p>
                        </Textblock>
                    </div>
                    <div className={this.state.loggedIn?"":"d-none"}>
                        <Textblock title="Create your own Patterns">
                            <p>Here you can create and edit Patterns to use for yourself, have others use on you or even use on others</p>
                            <p>Click on a Pattern to edit or delete it or click on the + button to add a new one</p>
                            <div className='d-grid gap-2'>
                                <div className='row g-2'>
                                    {this.state.patterns.map(
                                        (pattern, index) => ( 
                                            <div className='col-12 col-sm-4' key={index}>
                                                <Link to={`editpattern/${pattern.id}`}><button className='btn btn-light w-100 py-sm-5'>{pattern.pattern.name}</button></Link>
                                            </div>
                                        )
                                    )}
                                    <div className='col-12 col-sm-4'>
                                        <button className={`btn btn-outline-light w-100 py-sm-5 ${this.state.newLoading||this.state.patterns.length>=9?"d-none":""}`} title="Create new Pattern" onClick={this.createNew}><i className='bi bi-plus'/><span className='visually-hidden'>Create new Pattern</span></button>
                                        <button className={`btn btn-outline-light w-100 py-sm-5 ${this.state.newLoading?"":"d-none"}`} title="Loading" disabled><span className="spinner-border spinner-border-sm"/><span className='visually-hidden'>Loading</span></button>
                                    </div>
                                </div>
                            </div>
                        </Textblock>
                    </div>
                </div>
            </div>
        );
    }
}