import React, { Component } from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { CatPlugLocal } from './CatPlugLocal';
import { Textblock } from '../Textblock';
import { Header } from '../Header'
import WebService from '../../service/WebService';
import { CatPlugRemote } from './CatPlugRemote';
import { CatPlugControl } from './CatPlugControl';
import { PatternList } from './PatternList';
import { EditPattern } from './EditPattern';


export class CatPlug extends Component{
    static displayName="Hub for Buttplug Control";

    constructor(props){
        super(props)

        this.state = {
            LoggedIn: false,
            Roles: []
        };

        this.webservice = WebService.getInstance();

        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    onLogin(roles){
        this.setState({
            LoggedIn: true,
            Roles: roles
        });
    }

    onLogout(){
        this.setState({
            LoggedIn: false,
            Roles: []
        });
    }

    componentDidMount(){
        this.webservice.addListeners(this.onLogin, this.onLogout);
        if (this.webservice.isLoggedIn()){
            this.onLogin(this.webservice.getRoles());
        } else {
            this.onLogout();
        }
    }

    componentWillUnmount(){
        this.webservice.removeListeners(this.onLogin, this.onLogout);
    }

    render(){
        return(
            <div>
                <Route path="/catplug/local" component={CatPlugLocal} />
                <Route path="/catplug/localrs/:rsport" component={CatPlugLocal} />
                <Route path="/catplug/remote" component={CatPlugRemote} />
                <Route path="/catplug/remoters/:rsport" component={CatPlugRemote} />
                <Route path="/catplug/control/:subname/:identifyer" component={CatPlugControl} />
                <Route path="/catplug/configure" component={PatternList} />
                <Route path="/catplug/editpattern/:id" component={EditPattern} />
                <Route exact path="/catplug">
                    <div>
                        <Header />
                        <div className="container-sm pt-5 text-light">
                            <h1 className="text-center mb-5">Catplug</h1>
                            <Textblock title="Catplug Local">
                                <div className="d-flex align-items-end">
                                    <p className="grow-1">Play with smart toys connected to this device</p>
                                    <Link to="/catplug/local">
                                        <button title="To Catplug Local" className="btn btn-primary px-5 ml-2"><i className="bi bi-arrow-right"/><p className="visually-hidden">To Catplug Local</p></button>
                                    </Link>
                                </div>
                            </Textblock>
                            <Textblock title="Catplug Remote">
                                <div className="d-flex align-items-end">
                                    <p className="grow-1">Let your toys be controlled by a partner</p>
                                    <Link to="/catplug/remote" className={this.state.LoggedIn?'':'d-none'}>
                                        <button title="To Catplug Remote" className="btn btn-primary px-5 ml-2"><i className="bi bi-arrow-right"/><p className="visually-hidden">To Catplug Remote</p></button>
                                    </Link>
                                    <div className={this.state.LoggedIn?'d-none':''}>
                                        <button title="This Feature is for registered users only" className="btn btn-primary px-5 ml-2" disabled><i className="bi bi-arrow-right"/><p className="visually-hidden">This Feature is for the registered users only</p></button>
                                    </div>
                                </div>
                            </Textblock>
                            <Textblock title="Catplug Configure">
                                <div className="d-flex align-items-end">
                                    <p className="grow-1">Create Patterns to be used on your own or other Toys</p>
                                    <Link to="/catplug/configure" className={this.state.LoggedIn?'':'d-none'}>
                                        <button title="To Catplug Configure" className="btn btn-primary px-5 ml-2"><i className="bi bi-arrow-right"/><p className="visually-hidden">To Catplug Configure</p></button>
                                    </Link>
                                    <div className={this.state.LoggedIn?'d-none':''}>
                                        <button title="This Feature is for registered users only" className="btn btn-primary px-5 ml-2" disabled><i className="bi bi-arrow-right"/><p className="visually-hidden">This Feature is for the registered users only</p></button>
                                    </div>
                                </div>
                            </Textblock>
                            <Textblock title="What is Catplug">
                                <p>Catplug is an independend Progressive Web App for Controlling Smart Sex toys using <a href="https://buttplug.io/">buttplug.io</a>!</p>
                                <p>
                                    It lets you control toys with any amount of vibration motors via sliders or created patterns. Full support of buttplug.io features is planned.
                                </p>
                                <p>
                                    Browser Support:<br/>
                                    To connect bluetooth devices to your browser it needs to support Web Bluetooth. Google Chrome, Opera GX and Microsoft Edge have been tested and confirmed working. 
                                    Edge requires special configuration, while Chrome and Opera GX work out of the box.
                                </p>
                                <p>
                                    Catplug is free to use for everyone, but some features such as remote control and creating patterns requires you to be logged in.
                                </p>
                                <p>
                                    On data collection: <b>I do not collect any data that is not directly necessary for the function of the services.</b> <br/>
                                    I do not collect or track any statistics on what you do in my WebApps and will not share your information unless legally required.
                                </p>
                            </Textblock>
                            <Textblock title="Changelog">
                                <ul>
                                    <li>
                                        <b>V1.2.0</b>
                                        <ul>
                                            <li>Added Catplug Configure where everyone can configure up to 9 patterns to use on themselves, others or have others use on them</li>
                                            <li>The Editing interface is still a WIP and might be a bit janky. If you have ideas for improvement please do let me know</li>
                                            <li>Anyone can sign up for free now</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>V1.1.0</b>
                                        <ul>
                                            <li>Added the option to play pre programmed Patterns in Catplug Local and Remote</li>
                                            <li>The foundation for the creation and management of custom Patterns has been laid</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>V1.0.2</b>
                                        <ul>
                                            <li>Added Support for Local Buttplug servers like intiface</li>
                                            <li>Improved Connecting and Reconnecting Behavior</li>
                                            <li>Fix Controller not disconnecting on outdated link.</li>
                                            <li>Fix Copy Link not working in Firefox</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>V1.0.0:</b>
                                        <ul>
                                            <li>First implementation</li>
                                            <li>Control of vibration via sliders</li>
                                            <li>Implementation of Stop interface</li>
                                            <li>Interface to check Battery of Local and Remote toys</li>
                                            <li>Remote Control in Test Phase</li>
                                        </ul>
                                    </li>
                                </ul>
                            </Textblock>
                        </div>
                    </div>
                </Route>
            </div>
        );
    }
}