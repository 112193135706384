import React, {Component} from 'react';
import { Textblock } from '../Textblock';
import WebService from '../../service/WebService';

export class ButtplugDeviceControlInterface extends Component{
    static displayName="Control Interface";
    static defaultProps={
        sliderInputTimeOut: 100,
        connectionString: null
    };

    constructor(props){
        super(props);

        this.state = {
            Stop: props.device.AllowedMessages.includes(3),
            Vibrate: props.device.AllowedMessages.includes(0),
            CheckBattery: props.device.AllowedMessages.includes(8),

            VibrateValues: props.device.AllowedMessages.includes(0)?Array(props.device.messageAttributes(0).featureCount).fill(0):null,
            BatteryState: 0,
            IsCheckingBattery: props.isCheckingBattery,

            UseVibratePatterns: false,
            CurrentPattern: null,
            IsWaiting: false,
            PublicPatterns: [],
            MyPatterns: [],
            SubPatterns: null
        }

        this.logInfo = this.logInfo.bind(this);
        this.stop = this.stop.bind(this);
        this.vibrateChange = this.vibrateChange.bind(this);
        this.checkBattery = this.checkBattery.bind(this);
        this.showVibratePatterns =  this.showVibratePatterns.bind(this);
        this.showVibrateSliders =  this.showVibrateSliders.bind(this);
        this.playPattern = this.playPattern.bind(this);
        this.playNextPatternStep = this.playNextPatternStep.bind(this);

        this.vibrateChangeTimeoutID = null;
        this.webservice = WebService.getInstance();
    }

    async componentDidMount(){
        var patterns = await this.webservice.getAvailablePatterns({
            hubConnectionID: this.props.ConnectionId
        });
        this.setState({
            PublicPatterns: patterns.publicPatterns,
            MyPatterns: patterns.myPatterns,
            SubPatterns: patterns.subPatterns
        });
    }

    stop(){
        this.props.device.stop();
        this.setState({
            VibrateValues: this.state.Vibrate?Array(this.props.device.messageAttributes(0).featureCount).fill(0):null,
            CurrentPattern: null
        })
    }

    logInfo(){
        console.log(`Main Device: ${this.props.device.Name}`);
        console.log('The following message types are supported:');
        this.props.device.AllowedMessages.forEach(message => {
            console.log(`${message} (${this.getStringOfMessageType(message)}):`);
            var att = this.props.device.messageAttributes(message);
            console.log(`Feature Count: ${att.featureCount}`);
            console.log(`Endpoints: ${att.endpoints}`);
            console.log(`Max Durations: ${att.maxDuration}`);
            console.log(`StepCounts: ${att.stepCount}`);
        });
    }

    vibrateChange(event){
        event.persist();
        this.setState(state => {
            state.VibrateValues[event.target.name] = event.target.value;
            return state;
        }, ()=> {    
            const values = this.state.VibrateValues.map((val,i) => val/this.props.device.messageAttributes(0).stepCount[i]);
            if(this.vibrateChangeTimeoutID!==null) clearTimeout(this.vibrateChangeTimeoutID);
            this.vibrateChangeTimeoutID = setTimeout(() => this.props.device.vibrate(values), this.props.sliderInputTimeOut)
        });
    }

    playPattern(pattern){
        this.setState({
            CurrentPattern: pattern,
            IsWaiting: false
        }, () => {
            this.props.device.playPattern(pattern, () => {this.setState({IsWaiting:true})}, () => {this.setState({CurrentPattern: null})});
        });
    }

    playNextPatternStep(){
        if(!this.state.IsWaiting) return;
        this.setState({
            IsWaiting: false
        }, ()=>{
            this.props.device.nextPatternStep();
        });
    }

    checkBattery(){
        if(this.state.IsCheckingBattery){
            this.props.device.stopCheckingBattery();
            this.setState({IsCheckingBattery:false});
        } else {
            this.props.device.startCheckingBattery(v => {
                this.setState({
                    BatteryState: v
                });
            });
            this.setState({IsCheckingBattery:true});
        }
    }

    showVibratePatterns(){
        this.stop();
        this.setState({
            UseVibratePatterns: true
        })
    }

    showVibrateSliders(){
        this.stop();
        this.setState({
            UseVibratePatterns: false
        });
    }

    getStringOfMessageType(type){
        switch(type){
            case 0: return "VibrateCmd";
            case 1: return "RotateCmd";
            case 2: return "LinearCmd";
            case 3: return "StopDeviceCmd";
            case 4: return "RawReadCmd";
            case 5: return "RawWriteCmd";
            case 6: return "RawSubscribeCmd";
            case 7: return "RawUnsubscribeCmd";
            case 8: return "BatteryLevelCmd";
            case 9: return "RSSILevelCmd";
            default: return null;
        }
    }

    render(){
        return (
            <Textblock title={this.props.device.Name}>
                <div className={`mb-1 text-light border border-light rounded p-1 px-2 ${this.state.Vibrate?'':'d-none'}`}>
                    <div className='d-flex'>
                        <h3 className='grow-1'>Vibrate</h3>
                        <div className='mt-1 d-inline btn-group'>
                            <button className={`btn btn-${this.state.UseVibratePatterns?'outline-':''}light`} onClick={this.showVibrateSliders}>Sliders</button>
                            <button className={`btn btn-${this.state.UseVibratePatterns?'':'outline-'}light`} onClick={this.showVibratePatterns}>Patterns</button>
                        </div>
                    </div>
                    <div className={this.state.UseVibratePatterns?'d-none':''}>
                        {this.props.device.messageAttributes(0).stepCount.map(
                            (stepCount, index) => (
                            <div className='d-flex mt-2 align-items-center' key={index}>
                                <div className="grow-1">
                                    <label htmlFor={`${this.props.device.Name}-${this.props.index}-${index}`} className="w-30 mr-2 form-label">Motor {index+1}:</label>
                                    <input name={index} id={`${this.props.device.Name}-${this.props.index}-${index}`} className='form-range w-100' type='range' min='0' max={stepCount} value={this.state.VibrateValues[index]} onChange={this.vibrateChange}/>
                                </div>
                            </div>
                            )
                        )}
                    </div>
                    <div className={this.state.UseVibratePatterns?'':'d-none'}>
                        <div className='row'>
                                <div className='col-12 col-sm-4'>
                                    <p className='mb-1'><b>Public Patterns:</b></p>
                                    {this.state.PublicPatterns.map(
                                    (pattern, index) => (
                                        <div key={index} className='row'>
                                            <div className='col-12 d-flex align-items-start input-group mx-1 mb-1'>
                                                {this.state.CurrentPattern === pattern && 
                                                <button className='btn btn-outline-primary py-3 px-3' onClick={() => this.playPattern(pattern)}><i className='bi bi-arrow-clockwise' title='Restart'/><span className='visually-hidden'>Restart</span></button>}
                                                {this.state.CurrentPattern === pattern && 
                                                <button className='btn btn-outline-primary py-3 px-3' onClick={this.stop}><i className='bi bi-square-fill' title='Stop'/><span className='visually-hidden'>Stop</span></button>}
                                                <button key={index} disabled={this.state.CurrentPattern === pattern} className={`btn ${this.state.CurrentPattern === pattern?'btn-primary py-3':'btn-outline-primary'} grow-1 p-1`} title={pattern.pattern.description} onClick={() => this.playPattern(pattern)}>{pattern.pattern.name}<span className='visually-hidden'> Description: {pattern.pattern.description}</span></button>
                                                {this.state.CurrentPattern === pattern && 
                                                <button disabled={!this.state.IsWaiting} className={`btn ${this.state.IsWaiting?'btn-primary':'btn-outline-primary'} py-3 px-4`} onClick={this.playNextPatternStep}><i className='bi bi-arrow-bar-right' title="Continue"/><span className='visually-hidden'>Continue</span></button>}
                                            </div>
                                        </div>
                                        )
                                    )}
                                </div>
                                <div className='col-12 col-sm-4'>
                                    <p className='mb-1'><b>Your Patterns:</b></p>
                                    {this.state.MyPatterns.map(
                                    (pattern, index) => (
                                        <div key={index} className='row'>
                                            <div className='col-12 d-flex align-items-start input-group mx-1 mb-1'>
                                                {this.state.CurrentPattern === pattern && 
                                                <button className='btn btn-outline-primary py-3 px-3' onClick={() => this.playPattern(pattern)}><i className='bi bi-arrow-clockwise' title='Restart'/><span className='visually-hidden'>Restart</span></button>}
                                                {this.state.CurrentPattern === pattern && 
                                                <button className='btn btn-outline-primary py-3 px-3' onClick={this.stop}><i className='bi bi-square-fill' title='Stop'/><span className='visually-hidden'>Stop</span></button>}
                                                <button key={index} disabled={this.state.CurrentPattern === pattern} className={`btn ${this.state.CurrentPattern === pattern?'btn-primary py-3':'btn-outline-primary'} grow-1 p-1`} title={pattern.pattern.description} onClick={() => this.playPattern(pattern)}>{pattern.pattern.name}<span className='visually-hidden'> Description: {pattern.pattern.description}</span></button>
                                                {this.state.CurrentPattern === pattern && 
                                                <button disabled={!this.state.IsWaiting} className={`btn ${this.state.IsWaiting?'btn-primary':'btn-outline-primary'} py-3 px-4`} onClick={this.playNextPatternStep}><i className='bi bi-arrow-bar-right' title="Continue"/><span className='visually-hidden'>Continue</span></button>}
                                            </div>
                                        </div>
                                        )
                                    )}
                                </div>
                                {this.state.SubPatterns !== null &&
                                    <div className='col-12 col-sm-4'>
                                        <p className='mb-1'><b>Sub Patterns:</b></p>
                                        {this.state.SubPatterns.map(
                                        (pattern, index) => (
                                            <div key={index} className='row'>
                                                <div className='col-12 d-flex align-items-start input-group mx-1 mb-1'>
                                                    {this.state.CurrentPattern === pattern && 
                                                    <button className='btn btn-outline-primary py-3 px-3' onClick={() => this.playPattern(pattern)}><i className='bi bi-arrow-clockwise' title='Restart'/><span className='visually-hidden'>Restart</span></button>}
                                                    {this.state.CurrentPattern === pattern && 
                                                    <button className='btn btn-outline-primary py-3 px-3' onClick={this.stop}><i className='bi bi-square-fill' title='Stop'/><span className='visually-hidden'>Stop</span></button>}
                                                    <button key={index} disabled={this.state.CurrentPattern === pattern} className={`btn ${this.state.CurrentPattern === pattern?'btn-primary py-3':'btn-outline-primary'} grow-1 p-1`} title={pattern.pattern.description} onClick={() => this.playPattern(pattern)}>{pattern.pattern.name}<span className='visually-hidden'> Description: {pattern.pattern.description}</span></button>
                                                    {this.state.CurrentPattern === pattern && 
                                                    <button disabled={!this.state.IsWaiting} className={`btn ${this.state.IsWaiting?'btn-primary':'btn-outline-primary'} py-3 px-4`} onClick={this.playNextPatternStep}><i className='bi bi-arrow-bar-right' title="Continue"/><span className='visually-hidden'>Continue</span></button>}
                                                </div>
                                            </div>
                                            )
                                        )}
                                    </div>
                                }
                        </div>
                    </div>
                </div>
                <div className={`mb-1 d-grid ${this.state.Stop?'':'d-none'}`}>
                    <button className="btn btn-outline-primary p-1" onClick={this.stop} style={{height: '120px', fontSize:'50px'}}>Stop</button>
                </div>
                <div className={`mb-1 d-grid ${this.props.showInfo?'':'d-none'}`}>
                    <button className="btn btn-outline-light p-1" onClick={this.logInfo}>Info</button>
                </div>
                <div className={`mb-1 d-grid ${this.state.CheckBattery?'':'d-none'}`}>
                    <button className={`btn ${this.state.IsCheckingBattery?'btn-light':'btn-outline-light'} btn-block p-1 px-2`} onClick={this.checkBattery}>{this.state.IsCheckingBattery?`Battery: ${parseInt(this.state.BatteryState*100)}%`:'Start Checking Battery'}</button>
                </div>
            </Textblock>
        )
    }
}