import React, { Component } from 'react';
import { Header } from './Header';
import { Textblock } from './Textblock';
import { TextblockNSFW } from './TextblockNSFW';
import { Link } from 'react-router-dom';

export class Projects extends Component{
    static displayName = "Projects";

    render() {
        return (
            <div>
                <Header animated/>
                <div className="container container pt-5 text-light">
                    <h1 className="text-center mb-5">Projects</h1>
                    <Textblock title="This Website">
                        The only Project I can present here is this Website built with a full stack.<br/>
                        This is a Single Page Application built using React with a bit of Scroll-Magic and a lot of Bootstrap to make things prettier.
                        It is hosted on a Linux based server using a C# ASP.Net Backend with an Sqlite Database. More project will be added that take advantage of the rather heavy backend.
                    </Textblock>
                    <TextblockNSFW title="Catplug">
                        <div className="d-flex align-items-end">
                            <p className="grow-1">Catplug is an independend Progressive Web App for Controlling Smart Sex toys using <a href="https://buttplug.io/">buttplug.io</a>!</p>
                            <Link to="/catplug">
                                <button title="To Catplug" className="btn btn-primary px-5 ml-2"><i className="bi bi-arrow-right"/><p className="visually-hidden">To Catplug</p></button>
                            </Link>
                        </div>
                    </TextblockNSFW>
                </div>
            </div>
        );
    }
}