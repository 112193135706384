import React, {Component} from 'react';
import {Header} from './Header'
import { FunctionWithLogo} from './FunctionWithLogo';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { ResetPassword } from './ResetPassword';
import { ConfirmEmail } from './ConfirmEmail';

export class EmailHelper extends Component{
    static displayName = "Email Helper"

    render(){
        return(
            <div>
                <Header/>
                <div className="container-sm pt-5 text-light">
                    <div style={{marginTop: '10%'}}>
                        <Route path='/emailhelper/resetpassword/:email/:token' component={ResetPassword}/>
                        <Route path='/emailhelper/confirmemail/:email/:token' component={ConfirmEmail} />
                        <Route path='/emailhelper/success'>
                            <FunctionWithLogo title="Success">
                                <Route path='/emailhelper/success/resetpassword'>
                                    <p>Your password has been successfully reset. You can now login using your new password.</p>
                                    <Link to="/login"><button className="btn btn-outline-primary">To Login</button></Link>
                                </Route>
                                <Route path='/emailhelper/success/confirmemail'>
                                    <p>Your E-Mail has been successfully confirmed. You can now login using your new account.</p>
                                    <Link to="/login"><button className="btn btn-outline-primary">To Login</button></Link>
                                </Route>
                            </FunctionWithLogo>
                        </Route>
                    </div>
                </div>
            </div>
        );
    }
}