import React, {Component} from 'react';
import {Header} from './Header'
import WebService from '../service/WebService';
import { FunctionWithLogo } from './FunctionWithLogo';

export class SignUp extends Component{
    static displayName = "Sign Up"

    constructor(props){
        super(props);
        this.state = {
            EMail: "",
            Username: "",
            Password: "",
            PasswordHidden: true,
            Error: false,
            PasswordErr: Array(4).fill(false),
            Success: false,
            Valid: false,
            Loading: false
        };

        this.webservice = WebService.getInstance();

        this.signup = this.signup.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.passwordValidate = this.passwordValidate.bind(this);
        this.eye = this.eye.bind(this);

        this.PasswordTimeoutID = null;
    }

    signup(event){
        event.preventDefault();
        if(this.PasswordTimeoutID !== null) clearTimeout(this.PasswordTimeoutID);
        this.setState({
            Loading: true
        });
        this.webservice.signUp({
            eMail: this.state.EMail,
            userName: this.state.Username,
            password: this.state.Password
        }).then(res =>{
            this.setState({
                Loading: false,
                Success: true
            });
        }).catch(err => {
            this.setState({
                Loading: false,
                Error: true
            })
        }).finally(() => {});
        this.setState({
            Error: false,
            Password: "",
            PasswordErr: Array(4).fill(false)
        });
    }

    inputChange(event){
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            var res = true;
            if(this.state.EMail === "") res = false;
            if(!/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(this.state.EMail)) res = false;
            if(this.state.Username === "") res = false;
            if(this.state.Password === "") res = false;
            if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(this.state.Password)) res = false;
            this.setState({
                Valid: res
            });
            if(this.PasswordTimeoutID !== null) clearTimeout(this.PasswordTimeoutID);
            this.PasswordTimeoutID = setTimeout(() => this.passwordValidate(), 500);
        });
    }

    passwordValidate(){
        this.PasswordTimeoutID = null;
        var passwordErr = Array(4);
        passwordErr[0] = !/[\d]/.test(this.state.Password);
        passwordErr[1] = !/.{8,}/.test(this.state.Password);
        passwordErr[2] = !/[a-z]/.test(this.state.Password);
        passwordErr[3] = !/[A-Z]/.test(this.state.Password);
        if(this.state.Password === "") passwordErr = Array(4).fill(false);
        this.setState({
            PasswordErr: passwordErr
        })
    }

    eye(){
        this.setState({
            PasswordHidden: !this.state.PasswordHidden
        });
    }
    
    render(){
        return(
            <div>
                <Header />
                <div className="container-sm pt-5 text-light">
                    <div style={{marginTop: '10%'}}>
                        <FunctionWithLogo title="Sign Up">
                            <form className={this.state.Success?'d-none':''} onSubmit={this.signup}>
                                <label htmlFor="signup-email" className="visually-hidden">E-Mail Address. Used to Login</label>
                                <div className="input-group mb-3">
                                    <input id="signup-email" type="text" name="EMail" className="form-control bg-light text-dark" placeholder="E-Mail (Login)" value={this.state.EMail} onChange={this.inputChange} disabled={this.state.Loading}/>
                                </div>
                                <label htmlFor="signup-username" className="visually-hidden">Username</label>
                                <div className="input-group mb-3">
                                    <input id="signup-username" type="text" name="Username" className="form-control bg-light text-dark" placeholder="Username" value={this.state.Username} onChange={this.inputChange} disabled={this.state.Loading}/>
                                </div>
                                <label htmlFor="reset-password-password" className="visually-hidden">New Password</label>
                                <div className="input-group">
                                    <input id="reset-password-password" type={this.state.PasswordHidden?'password':'text'} name="Password" className="form-control bg-light text-dark" placeholder="Password" value={this.state.Password} onChange={this.inputChange} disabled={this.state.Loading}/>
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-light" onClick={this.eye} title="Toggle wether password is hidden or not">
                                            <i className={`bi ${this.state.PasswordHidden?'bi-eye-slash-fill':'bi-eye-fill'}`}/>
                                        </button>
                                    </div>
                                </div>
                                <small className={`form-text text-danger ${this.state.Error?'':'d-none'}`}>Account could not be Created, please check your inputs or reset your password instead.<br/></small>
                                <small className={`form-text text-danger ${this.state.PasswordErr[0]?'':'d-none'}`}>Password requires a digit (0-9)<br/></small>
                                <small className={`form-text text-danger ${this.state.PasswordErr[1]?'':'d-none'}`}>Password requires at least 8 letters<br/></small>
                                <small className={`form-text text-danger ${this.state.PasswordErr[2]?'':'d-none'}`}>Password requires a lowercase letter<br/></small>
                                <small className={`form-text text-danger ${this.state.PasswordErr[3]?'':'d-none'}`}>Password requires an uppercase letter<br/></small>
                                <button type="submit" className={`btn btn-primary px-3 mt-3 ${this.state.Loading?'d-none':''}`} disabled={!this.state.Valid}>Sign Up</button>
                                <button type="button" className={`btn btn-primary px-3 mt-3 ${this.state.Loading?'':'d-none'}`} disabled><span className="spinner-border spinner-border-sm"/> Loading...</button>
                            </form>
                            <div className={this.state.Success?'':'d-none'}>
                                <p>Thank you for Signing up NimmerNeko.de <br/> 
                                Before you can login please confirm your E-Mail Adress. You will receive a Mail shortly so please be certain to check your inbox and spam folder.</p>
                            </div>
                        </FunctionWithLogo>
                    </div>
                </div>
            </div>
        );
    }

}