export class MockDeviceWrapper{
    constructor(mockdevice, connection){
        this._mockdevice = mockdevice;
        this._connection = connection;
        this._index = mockdevice.Index;
        this.AllowedMessages =  mockdevice.AllowedMessages;
        this.Name = mockdevice.Name;
        this._batteryCallback = null;
        this._waitCallback = null;
        this._endCallback = null;
        this.CurrentPattern = null;
        this.CurrentPatternStep = 0;
        this.CurrentPatternRepeat = 0;
        this.CurrentPatternWaiting = false;
    }

    sendToSub(command, args){
        this._connection.invoke("sendToSub", this._index, command, args);
    }

    stop(){
        this.sendToSub("stop", null)
    }

    vibrate(values){
        this.sendToSub("vibrate", values);
    }

    startCheckingBattery(callback){
        this._batteryCallback = callback;
        this.sendToSub("startCheckingBattery", null);
    }

    receiveCommand(command, args){
        switch(command){
            case "setBatteryLevel":
                this._batteryCallback(args);
                break;
            case "callWaitCallback":
                this._waitCallback();
                break;
            case "callEndCallback":
                this._endCallback();
                break;
            default:
                console.log(command);
                console.log(args);
                break;
        }
    }

    stopCheckingBattery(){
        this.sendToSub("stopCheckingBattery", null)
    }

    playPattern(pattern, waitCallback, endCallback){
        this._waitCallback = waitCallback;
        this._endCallback = endCallback; 
        this.sendToSub("playPattern", pattern);
    }

    nextPatternStep(){
        this.sendToSub("nextPatternStep");
    }

    messageAttributes(message){
        return this._mockdevice.MessageAttributes[message];
    }
}