import React, {Component} from 'react';
import WebService from '../service/WebService';
import { Redirect, Link } from 'react-router-dom';
import { FunctionWithLogo } from './FunctionWithLogo';

export class ConfirmEmail extends Component{
    static displayName = "Confirm Email"

    constructor(props){
        super(props);

        this.state = {
            Redirect: false,
            Error: false
        };

        this.webservice = WebService.getInstance();
    }

    componentDidMount(){
        this.webservice.confirmEmail({
            eMail: decodeURIComponent(this.props.match.params.email),
            token: decodeURIComponent(this.props.match.params.token)
        }).then(res => {
            this.setState({
                Redirect: "/emailhelper/success/confirmemail"
            });
        }).catch(err => {
            this.setState({
                Error: true
            });
        }).finally(() => {});
    }

    render(){
        if(this.state.Redirect){
            return <Redirect to={this.state.Redirect}/>
        }
        return(
            <FunctionWithLogo title="Confirm E-Mail">
                <div className={`text-center ${this.state.Error?'d-none':''}`}>
                <span className="spinner-border mb-3"/>
                <br/>Your E-Mail address is being confirmed...
                </div>
                <div className={`text-danger ${this.state.Error?'':'d-none'}`}>
                <p>Your-Email address could not be confirmed. This link may have already been used.<br/>In this case please login.</p>
                <Link to="/login"><button className="btn btn-outline-primary">To Login</button></Link>
                </div>
            </FunctionWithLogo>
        );
    }

}