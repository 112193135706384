import React, {Component} from 'react';
import {Header} from '../Header';
import { Textblock } from '../Textblock';
import { ButtplugDeviceControlInterface } from './ButtplugDeviceControlInterface';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { MockDeviceWrapper } from './MockDeviceWrapper';

export class CatPlugControl extends Component{
    static displayName = "Buttplug Interface";

    constructor(props){
        super(props);

        this.state = {
            Init: false, 
            Devices: [],
            Name: "",
            Error: false,
            SubName: decodeURIComponent(this.props.match.params.subname),
            ConnectionId: null
        };

        this.signalrConnection = null;

        this.receiveCommand = this.receiveCommand.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.init = this.init.bind(this);
    }

    async init(event){
        event.preventDefault();
        this.signalrConnection = new HubConnectionBuilder().withUrl("/Api/Hubs/PlugHub").build();
        this.signalrConnection.on("receiveCommand", this.receiveCommand);
        this.signalrConnection.start().then(() => {
            this.signalrConnection.invoke("ConnectToSub", decodeURIComponent(this.props.match.params.identifyer), this.state.Name).catch(e => {});
            this.setState({
                ConnectionId: this.signalrConnection.connectionId
            });
        }).catch( err => {
            this.setState({
                Init: false
            });
        });
        this.setState({
            Init: true
        });
    }

    receiveCommand(dev, command, args){
        //console.log(dev);
        //console.log(command);
        //console.log(args);
        if(dev !== null){
            this.state.Devices.filter(d => d._index===dev)[0].receiveCommand(command, args);
            return;
        }
        switch(command){
            case "sendName":
                this.signalrConnection.invoke("SendToSub", null, "setDomName", this.state.Name);
                this.signalrConnection.invoke("SendToSub", null, "sendDeviceList", null);
                break;
            case "setDeviceList":
                this.setState(state => {
                    state.Devices = [];
                    for(var i = 0; i<args.length; i++){
                        state.Devices.push(new MockDeviceWrapper(args[i], this.signalrConnection));
                    }
                    return state;
                });
                break;
            case "disconnect":
                this.setState({
                    Devices: [],
                    Name: "",
                    Init: false,
                    Error: true,
                    ConnectionId: null
                });
                this.signalrConnection.stop();
                break;
            default: 
                console.log(command);
                console.log(args);
        }
    }

    inputChange(event){
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render(){
        return(
            <div>
                <Header minimal/>
                <div className="container-sm pt-5 text-light">
                    <div className={this.state.Init?'':'d-none'}>
                        <h1 className="text-center mb-5">Catplug Control ({this.state.SubName})</h1>
                        {this.state.Devices.map((dev, i) => (
                            <ButtplugDeviceControlInterface ConnectionId={this.state.ConnectionId} key={i} index={i} device={dev}/>
                        ))}
                        <div className={`${this.state.Devices.length===0?'':'d-none'}`}>
                            <Textblock title="No Devices">
                                There are currently no devices connected, please wait for more devices to be connected.
                            </Textblock>
                        </div>
                    </div>
                    <div className={this.state.Init?'d-none':''}>
                        <h1 className="text-center mb-5">Catplug Control</h1>
                        <Textblock title={`${this.state.SubName} has invited you to control their Toys`}>
                            <form className="row row-cols-auto g-2 align-items-center pt-3" onSubmit={this.init}>
                                <div>
                                    <label htmlFor="catplug-control-name" className="visually-hidden"></label>
                                    <div className="input-group">
                                        <input id="catplug-control-name" name="Name" type="text" className="form-control bg-light text-dark" placeholder="Give yourself a name" value={this.state.Name} onChange={this.inputChange}/>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary px-3" disabled={this.state.Name.length===0}>Go!</button>
                                </div>
                            </form>
                        </Textblock>
                        <div className={this.state.Error?'':'d-none'}>
                            <Textblock title="Error">
                                This link is no longer valid
                            </Textblock>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}