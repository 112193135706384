import React, { Component } from 'react';

export class Textblock extends Component {
  static displayName = Textblock.name;
  
  render(){
    return (
        <div className="mb-5">
            <h3 className="px-2 pb-1 bg-light text-dark rounded">{this.props.title}</h3>
            <div className="px-1">
                {this.props.children}
            </div>
        </div>
    );
  }
}