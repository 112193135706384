import React, { Component } from 'react';
import { Header } from './Header';
import { Textblock } from './Textblock';

export class Home extends Component {
  static displayName = "Home"

  render () {
    return (
      <div>
        <Header animated/>
        <div className="container pt-5 text-light">
          <h1 className="text-center mb-5">Willkommen</h1>
          <Textblock title="Info">
          Hallo!<br/><br/>
          Mein Name ist Malte Fischer aka NimmerNeko und ich bin Software Entwickler mit einem unglücklichen Fokus auf Webentwicklung und noch andere dinge.<br/><br/>
          Dies hier ist meine persönliche Website. Noch ist hier nicht viel, aber dies ist der Ort wo meine Projekte nach und nach auftauchen werden.<br/>
          Auch weitere Informationen über mich werden hier wahrscheinlich irgendwann zu finden sein.<br/><br/>
          Ciao
          </Textblock>
          <Textblock title="Info (but in english)">
          Hello!<br/><br/>
          My name is Malte Fischer aka NimmerNeko and I am a german Software Developer with an unfortunate focus on web-development along with some other things.<br/><br/>
          This here is my website. There is not a lot going on now but over time I will publish my personal Projects on here.<br/>
          Eventually I am sure information about me as a person will be to find here as well.<br/><br/>
          Ciao
          </Textblock>
          <Textblock title="Information about contacting me">
            I am open for being contacted regarding interesting positions and projects.
          </Textblock>
        </div>
      </div>
    );
  }
}
