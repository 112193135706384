import axios from "axios";

export default class WebService{
    static _instance = null;

    _token = null;
    _roles = [];
    _intervalID = null;
    loginListeners = [];
    logoutListeners = [];

    static getInstance(){
        if (WebService._instance == null){
            WebService._instance = new WebService();
            WebService._instance._tryLogin();
        }
        return WebService._instance;
    }

    addListeners(login, logout){
        this.loginListeners.push(login);
        this.logoutListeners.push(logout);
    }

    removeListeners(login, logout){
        const index = this.loginListeners.indexOf(login);
        if (index > -1) {
            this.loginListeners.splice(index, 1);
        }
        const index2 = this.logoutListeners.indexOf(logout);
        if (index2 > -1) {
            this.logoutListeners.splice(index, 1);
        }
    }

    async login(loginObj){
        var res = await axios.post("/Api/Auth/Login", loginObj);
        if(res.data.token === undefined) return {};
        this._token = res.data.token;
        this._roles = res.data.roles;
        this._startTokenInterval();
        this.loginListeners.forEach(e => e(this._roles));
        return res.data;
    }

    async logout(){
        await axios.post("/Api/Auth/ClearCookies");
        this._stopTokenInterval();
        this._token = null;
        this.roles = [];
        this.logoutListeners.forEach(e => e());
    }

    async requestPasswordReset(rprObj){
        var res = await axios.post("/Api/Auth/RequestPasswordReset", rprObj);
        return res.data;
    }
    async resetPassword(rpObj){
        var res = await axios.post("/Api/Auth/ResetPassword", rpObj);
        return res.data;
    }

    async signUp(suObj){
        var res = await axios.post("/Api/Auth/Register", suObj);
        return res.data;
    }

    async confirmEmail(ceObj){
        var res = await axios.post("/Api/Auth/ConfirmEmail", ceObj);
        return res.data;
    }

    async getUser(){
        var conf = {
            headers:{
                Authorization: "Bearer " + this._token
            }
        };
        var res = await axios.get("/Api/Auth/GetUser", conf);
        return res.data;
    }

    async getAvailablePatterns(gapObj){
        var conf = {
            headers:{
                Authorization: "Bearer " + this._token
            }
        };
        var res = await axios.post("/Api/Pattern/GetAvailablePatterns", gapObj, conf);
        return res.data;
    }

    async getMyPatterns(){
        var conf = {
            headers:{
                Authorization: "Bearer " + this._token
            }
        };
        var res = await axios.get("/Api/Pattern/GetMyPatterns", conf);
        return res.data;
    }

    async getPattern(id){
        var conf = {
            headers:{
                Authorization: "Bearer " + this._token
            }
        };
        var url = "/Api/Pattern/GetPattern";
        if (id !== null) url += `?id=${id}`;
        var res = await axios.get(url, conf);
        return res.data;
    }

    async updatePattern(pattern){
        var conf = {
            headers:{
                Authorization: "Bearer " + this._token
            }
        };
        var res = await axios.post("/Api/Pattern/UpdatePattern", pattern, conf);
        return res.data;
    }

    async deletePattern(delPatObj){
        var conf = {
            headers:{
                Authorization: "Bearer " + this._token
            }
        };
        var res = await axios.post("/Api/Pattern/DeletePattern", delPatObj, conf);
        return res.data;
    }

    isLoggedIn(){
        return this._token != null;
    }

    getRoles(){
        return this._roles;
    }

    _tryLogin(){
        this._refreshToken().then(res => {
            this._token = res.token;
            this._roles = res.roles;
            this.loginListeners.forEach(e => e(this._roles));
            this._startTokenInterval();
        }).catch(err => {});
    }

    async _refreshToken(){
        var res = await axios.get("/Api/Auth/RefreshToken");
        return res.data;
    }

    _interval(){
        this._refreshToken().then((res) => {
            this._token = res.token;
            this._roles = res.roles;
        }).catch(err => {
            this._token = null;
            this._stopTokenInterval();
        });
    }

    _startTokenInterval(){
        if(this._intervalID != null) return;
        this._intervalID = setInterval(() => this._interval(), 600000);
    }

    _stopTokenInterval(){
        clearInterval(this._intervalID);
        this._intervalID = null;
    }
}