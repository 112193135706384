export class DeviceWrapper {

    constructor(device){
        this._device = device;
        this.AllowedMessages =  device.AllowedMessages;
        this.Name = device.Name;
        this.BatteryIntervalID = null;
        this.BatteryCallback = null;
        this.CurrentPattern = null;
        this.CurrentPatternStep = 0;
        this.CurrentPatternRepeat = 0;
        this.CurrentPatternWaiting = false;

        this._timeout = null;
        this._waitCallback = null;
        this._endCallback = null;
    }

    stop(){
        clearTimeout(this._timeout);
        this._device.stop().catch(e => {});
    }

    vibrate(values){
        this._device.vibrate(values).catch(e => {});
    }

    async batteryLevel(){
        try{
            return await this._device.batteryLevel();
        } catch(e){
            
        }
    }

    startCheckingBattery(callback){
        if(this.BatteryIntervalID !== null) return;
        this.BatteryCallback = callback;
        this._device.batteryLevel().then((v) => this.BatteryCallback(v));
        this.BatteryIntervalID = setInterval(() => this._device.batteryLevel().then((v) => this.BatteryCallback(v)) ,5000);
    }

    stopCheckingBattery(){
        if(this.BatteryIntervalID === null) return;
        clearInterval(this.BatteryIntervalID);
        this.BatteryIntervalID = null;
        this.BatteryCallback = null;
    }

    playPattern(pattern, waitCallback, endCallback){
        clearTimeout(this._timeout);
        this.CurrentPattern = pattern.pattern;
        this._waitCallback = waitCallback;
        this._endCallback = endCallback;
        this.CurrentPatternStep = 0;
        this.CurrentPatternRepeat = 0;
        this.CurrentPatternWaiting = false;
        this._nextStep();
    }

    nextPatternStep(){
        if(this.CurrentPattern == null) return;
        if(this.CurrentPatternWaiting) return;
        this._nextStep();
    }

    _nextStep(){
        if(this.CurrentPatternStep === this.CurrentPattern.steps.length){
            this.CurrentPattern = null;
            this.stop();
            this._endCallback();
            return;
        }
        var step = this.CurrentPattern.steps[this.CurrentPatternStep];
        this._device.vibrate(this._makeInputs(step.vibrate));
        this.CurrentPatternStep += 1;
        if(this.CurrentPatternStep === this.CurrentPattern.steps.length && this.CurrentPattern.repeating && (this.CurrentPattern.amountOfRepeats === null || this.CurrentPatternRepeat < this.CurrentPattern.amountOfRepeats - 1)){
            this.CurrentPatternRepeat += 1;
            this.CurrentPatternStep = 0;
        }
        if(step.hold){
            this.CurrentPatternWaiting = true;
            this._timeout = setTimeout(() => this._wait(), step.length);
        } else {
            console.log(step.length)
            this._timeout = setTimeout(() => this._nextStep(), step.length);
        }
    }

    _wait(){
        this.CurrentPatternWaiting = false;
        this._waitCallback();
    }

    _makeInputs(inputs){
        var devInp = this._device.messageAttributes(0).featureCount;
        if (devInp === inputs.inputs.length) return inputs.inputs;
        var res = new Array(devInp);
        if(devInp > inputs.inputs.length){
            for (var i = 0; i < devInp; i++){
                if (i < inputs.inputs.length){
                    res[i] = inputs.inputs[i];
                } else {
                    res[i] = inputs.inputs[inputs.mainInput];
                }
            }
        } else if (inputs.mainInput < devInp){
            for (i = 0; i < devInp; i++){
                res[i] = inputs.inputs[i];
            }
        } else {
            for (i = 0; i < devInp - 1; i++){
                res[i] = inputs.inputs[i];
            }
            res[devInp-1] = inputs.inputs[inputs.mainInput];
        }
        return res;
    }

    messageAttributes(message){
        return this._device.messageAttributes(message);
    }

    beforeDisconnect(){
        this.stopCheckingBattery();
    }

}